import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Barcode from "react-barcode";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PrintBarcode({ handleCloseBarcode, openBarcode, selectedProducts }) {
  const generateBarcodeImage = (sku) => {
    const canvas = document.createElement("canvas");
    const barcode = Barcode(canvas);
    barcode.init({
      value: sku,
      width: 1,
      height: 46,
      fontSize: 12,
      margin: 2,
    });
    return canvas.toDataURL();
  };
  //   console.log(selectedProducts);
  const handlePrint = () => {
    // Create a new window
    const printWindow = window.open("", "", "height=600,width=1200");

    // Write the content to the new window
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
        
          <style>
            @media print {
              .print-barcode {
                margin: 15px 10px;
                page-break-inside: avoid; /* Avoid breaking inside a barcode section */
                page-break-before: always; /* Force a page break before each barcode section */
              }
              .barcodeName {
                margin: 5px 0;
              }
              @page {
                size: auto;
                margin: 20mm;
              }
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
        
          ${selectedProducts
            .map((data) =>
              Array.from({ length: data.quantity })
                .map(
                  () =>
                    `
                <div class="print-barcode">
                  <h5 class="barcodeName">PRODUCT NAME: <b>${
                    data.product_name.length > 45
                      ? data.product_name.substring(0, 45) + "..."
                      : data.product_name
                  }</b></h5>
                  <h5 class="barcodeName">PART NO.: <b>${
                    data.part_number.includes(",")
                      ? data.part_number.split(",")[0]
                      : data.part_number.length > 40
                      ? data.part_number.substring(0, 20)
                      : data.part_number
                  }</b></h5>
                  <h5 class="barcodeName my-3">BRAND: <b>${
                    data.product_brand
                  }</b></h5>
                  <h5 class="barcodeName">QTY: <b>1PC</b></h5>
                  <div style="position: absolute; right: 10px; bottom: 5px;">
                  
                      <Barcode
                        width={1}
                        height={46}
                        fontSize={12}
                        margin={2}
                        value=${data.product_sku}
                      />
                    
                  </div>
                </div>
              `
                )
                .join("")
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div>
      <Modal
        open={openBarcode}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseBarcode();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 className="page-title">Print Barcodes</h3>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginTop: "2px",
              padding: "20px",
            }}
          >
            {selectedProducts.map((data, index) =>
              Array.from({ length: data.quantity }).map((_, i) => (
                <div
                  key={`${index}-${i}`}
                  className="mainBarcode print-barcode"
                >
                  <h5 className="barcodeName">
                    PRODUCT NAME:{" "}
                    <b>
                      {data.product_name.length > 45
                        ? data.product_name.substring(0, 45) + "..."
                        : data.product_name}
                    </b>
                  </h5>
                  <h5 className="barcodeName">
                    PART NO.:{" "}
                    <b>
                      {data.part_number.includes(",")
                        ? data.part_number.split(",")[0]
                        : data.part_number.length > 40
                        ? data.part_number.substring(0, 20)
                        : data.part_number}
                    </b>
                  </h5>

                  <h5 className="barcodeName">
                    BRAND: <b>{data.product_brand}</b>
                  </h5>
                  <h5 className="barcodeName">
                    QTY: <b>1PC</b>
                  </h5>

                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "5px",
                    }}
                  >
                    <Barcode
                      width={1}
                      height={46}
                      fontSize={12}
                      margin={2}
                      value={data.product_sku}
                    />
                  </div>
                </div>
              ))
            )}
          </TableContainer>
          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px" }}
              onClick={handlePrint}
              variant="outlined"
            >
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PrintBarcode;
