import React from "react";

import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// Create a custom toolbar without export options
const CustomToolbar = () => (
  <GridToolbarContainer>
    {/* <GridToolbarFilterButton /> */}

    <GridToolbarColumnsButton />
    <GridToolbarDensitySelector />
  </GridToolbarContainer>
);

export default CustomToolbar;
