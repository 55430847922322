import React, { useState, useEffect } from "react";
import axios from "axios";

import { fetchRowInven, apiKey, fetch_racks } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./RowRackInvenPopUp";
import ViewRacks from "./UpdateRows/RacksUpdateModal";
import AddNewRow from "./UpdateRows/AddNewRow";
import DeleteRowModal from "../../Messages/DeleteWarehouse/DeleteRow";
import EditRow from "./UpdateRows/EditRowModal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function RowRackDetails() {
  const [rows, setRows] = useState([]);
  const [inven, setInven] = useState([]);
  const [error, setError] = useState(null);
  const [row, setRow] = useState("");
  const [racks, setRacks] = useState([]);
  // const [openDelete, setOpenDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openAddRowModal, setOpenAddRowModal] = useState(false);
  const [openEditRowModal, setOpenEditRowModal] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const [allocated, setAllocated] = useState(0);
  const [unallocated, setUnAllocated] = useState(0);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(
        fetchRowInven,

        {
          params: {
            warehouse_id: id,
          },
          headers: {
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        setWarehouse(response.data.obj.ware_house);
        setAllocated(response.data.obj.allocated);
        setUnAllocated(response.data.obj.unallocated);
        setRows(response.data.row_inventory);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleInventory = (index, row) => {
    setOpenModal(true);

    try {
      axios
        .get(fetchRowInven, {
          params: {
            warehouse_id: id,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          //   console.log(index, "row");
          // console.log(response.data.row_inventory[index].inventory);

          setInven(response.data.row_inventory[index].inventory);
          setOpenModal(true);
          setRow(row);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [row_id, setRowId] = useState("");

  const handleViewClick = (rowid, row) => {
    setRowId(rowid);
    setOpenViewModal(true);

    try {
      axios
        .get(fetch_racks, {
          params: {
            row_pk: parseInt(rowid),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setRacks(response.data.racks);
          // console.log(response.data.racks);
          setRow(row);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteRow = (rowid) => {
    setRowId(rowid);
    setOpenDeleteRow(true);
  };

  const handleEditRow = (rowid, row) => {
    setRowId(rowid);
    setRow(row);

    setOpenEditRowModal(true);
  };

  const handleAddRowClick = () => {
    setOpenAddRowModal(true);
  };

  return (
    <>
      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        inven={inven}
        row_name={row}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <AddNewRow
        openModal={openAddRowModal}
        setOpenModal={setOpenAddRowModal}
        ware_id={id}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <DeleteRowModal
        openDeleteRow={openDeleteRow}
        setOpenDeleteRow={setOpenDeleteRow}
        fetchData={fetchData}
        row_id={row_id}
      />

      <EditRow
        openEditRowModal={openEditRowModal}
        setOpenEditRowModal={setOpenEditRowModal}
        row_name={row}
        row_id={row_id}
        fetchData={fetchData}
      />

      <ViewRacks
        openModal2={openViewModal}
        setOpenModal2={setOpenViewModal}
        racks={racks}
        row_name={row}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-2)}
            >
              Inventory
            </Typography>
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Row Rack
            </Typography>
            <Typography sx={{ color: "text.primary" }}>{warehouse}</Typography>
          </Breadcrumbs>

          <div
            style={{
              height: "84vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="p-3 row container-fluid my-2">
              <div className="col-lg-4">
                Warehouse: <b style={{ color: "black" }}>{warehouse || ""}</b>
              </div>
              <div className="col-lg-4">
                Allocated: <b style={{ color: "black" }}>{allocated || 0}</b>
              </div>
              <div className="col-lg-4">
                Unallocated: <b style={{ color: "red" }}>{unallocated || 0}</b>
                <Button
                  onClick={() => handleAddRowClick()}
                  style={{ float: "right" }}
                  variant="outlined"
                  size="small"
                >
                  Add New Row
                </Button>
              </div>
            </div>
            <div className="container-fluid">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "5px" }}>#</TableCell>
                      <TableCell align="left">Row</TableCell>
                      <TableCell align="left">Warehouse</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "5px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          {row.row_name}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ width: "33%", color: "#bababa" }}
                        >
                          {warehouse}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              handleViewClick(row.id, row.row_name)
                            }
                          >
                            View
                          </Button>
                          <Button
                            style={{ marginLeft: "5px" }}
                            variant="outlined"
                            color="success"
                            size="small"
                            onClick={() => handleInventory(index, row.row_name)}
                          >
                            Inventory
                          </Button>

                          <Button
                            style={{ marginLeft: "5px" }}
                            variant="outlined"
                            size="small"
                            color="info"
                            onClick={() => handleEditRow(row.id, row.row_name)}
                          >
                            Edit
                          </Button>

                          {/* <Button
                            style={{ marginLeft: "5px" }}
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={() => handleDeleteRow(row.id)}
                          >
                            Delete
                          </Button> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <DataGrid
                // slots={{ toolbar: CustomToolbar }}
                rows={rows}
                columns={columns}
                // loading={loading}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RowRackDetails;
