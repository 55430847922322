import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_warehouse, fetchProductInven } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AssignWare from "./ProductInvenAdjust";
import AssignRackModal from "./AssignRackModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Inventory_ProductsModal({
  setOpenModal,
  openModal,
  inventory,
  p_sku,
  setInven,
  fetchData,
  unallocated,
  setUnallocated,
}) {
  const [error, setError] = useState("");
  const [inven_id, setID] = useState("");
  const [quantity, setQty] = useState("");
  const [openModal2, setOpenModal2] = useState(false);
  const [openUnallocatedModal, setOpenUnallocatedModal] = useState(false);
  const [openError, setOpenError] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const handleClose = () => {
    setOpenModal(false);
    setWarehouse(userDetails.primary_warehouse_pk);
    setError(""); // Clear error on close
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
      });
  };

  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    fetchDataa();
  }, []);

  const fetchDataa = () => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: p_sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.row_inventory);
        setInven(response.data.row_inventory);
        setUnallocated(response.data.obj.unallocated);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleWarehouseChange = (e) => {
    const selectedWarehouse = e.target.value; // Use the selected value directly
    setWarehouse(selectedWarehouse);

    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: selectedWarehouse, // Use the selected warehouse value here
          sku: p_sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setInven(response.data.row_inventory);
        setUnallocated(response.data.obj.unallocated);
        setOpenModal(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleEditClick = (id, qty) => {
    setOpenModal2(true);
    setID(id);
    setQty(qty);
  };

  const handleUnallocated = () => {
    setOpenUnallocatedModal(true);
  };

  // console.log(p_sku, "sku");
  return (
    <>
      <AssignWare
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        inven={inven_id}
        qty={quantity}
        setQty={setQty}
        fetchDataa={fetchDataa}
        setWarehouse={setWarehouse}
        warehouse={warehouse}
        fetchData={fetchData}
      />

      <AssignRackModal
        openModal={openUnallocatedModal}
        setOpenModal={setOpenUnallocatedModal}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
        warehouseId={warehouse}
        p_sku={p_sku}
        fetchDataa={fetchDataa}
      />

      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              All Products
            </Typography>

            <Typography sx={{ color: "text.primary" }}>Inventory</Typography>
          </Breadcrumbs>

          <FormControl
            style={{
              marginTop: "15px",
              float: "right",
              width: "15vw",
              marginTop: "-18px",
              marginBottom: "5px",
            }}
          >
            <InputLabel id="demo-simple-select-label">Warehouse</InputLabel>
            <Select
              size="small"
              value={warehouse}
              label="Warehouse"
              onChange={handleWarehouseChange}
            >
              {warehouses.map((ware) => (
                <MenuItem key={ware.id} value={ware.id}>
                  {ware.ware_house}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "rgb(229, 246, 253)" }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Row</TableCell>
                  <TableCell align="left">Rack</TableCell>
                  <TableCell align="left">Bin</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  {/* <TableCell align="left">Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory.length > 0 ? (
                  <>
                    {inventory &&
                      inventory.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {row.row_ref ? row.row_ref.row_name : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.rack ? row.rack.rack_name : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.bin_ref ? row.bin_ref.bin_name : "-"}
                          </TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          {/* <TableCell align="left">
                             <Button
                              size="small"
                              onClick={() =>
                                handleEditClick(row.id, row.quantity)
                              }
                              variant="outlined"
                            >
                              Adjust
                            </Button> 
                          </TableCell>*/}
                        </TableRow>
                      ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan="6"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No allocated products found
                      </TableCell>
                    </TableRow>
                  </>
                )}

                {unallocated && unallocated > 0 ? (
                  <>
                    <TableRow
                      style={{ backgroundColor: "#ffe6db" }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        style={{ color: "red" }}
                      >
                        UnAllocated Inventory:
                        <span style={{ color: "black" }}>
                          {" "}
                          {unallocated} UnAllocated Products
                        </span>
                      </TableCell>

                      <TableCell align="left">
                        <Button
                          size="small"
                          color="error"
                          onClick={() => handleUnallocated()}
                          variant="outlined"
                        >
                          Allocate
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            style={{ marginTop: "8px", float: "right" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default Inventory_ProductsModal;
