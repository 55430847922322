import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_all_product,
  fetch_customer,
  create_sale,
  getLastPrice,
  fetch_customer_address,
  fetch_sale_detail,
} from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";
import CreateCustomer from "./CreateCustomer";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import BarcodeProducts from "./BarcodeProducts";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";
import { Popup } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";

function EditSale() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
  const [last_price, setLast_price] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [customeraddress, setCustomeraddress] = useState([]);
  const [address, setAddress] = useState("");
  const [discount_amount, setDiscount] = useState("");
  const [sale_date, setS_date] = useState("");
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [totalamount, setTotalAMount] = useState(0);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [discounts, setDiscounts] = useState({});
  const [numericDiscounts, setNumericDiscounts] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    fetchCustomers();
    fetchSales();
  }, [searchquery]);

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setCustomer(response.data.data.billing_customer.id);
        setAddress(response.data.data.billing_customer.primary_shipping.id);
        setS_date(response.data.data.sale_date);
        setDiscountedPrice(response.data.data.discount_amount);
        setTotalAMount(response.data.data.total_amount);

        setSelectedProducts(response.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value, customer_pk: parseInt(customer) },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        console.log(response.data.data.products);
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer] // Dependency array
  );

  // Handle input change, using debounceFetchProducts
  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const handleCustomerChange = (e) => {
    const customer_pk = e.target.value;
    setCustomer(customer_pk);

    // Create an array of SKUs from selected products
    const skus = selectedProducts.map((product) => product.product_sku);

    try {
      axios
        .get(fetch_customer_address, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            customer_pk: customer_pk,
          },
        })
        .then((response) => {
          // console.log(response.data.address);
          setCustomeraddress(response.data.address);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}

    // Fetch updated prices for the new customer
    try {
      axios
        .post(
          getLastPrice,
          { customer_pk, skus },
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          // Convert the array to an object for easy lookup
          const lastMrpsData = response.data.prices;
          const lastMrpsDataObject = lastMrpsData.reduce((acc, item) => {
            acc[item.sku] = item.last_price;
            return acc;
          }, {});

          // Update selectedProducts with the new last_price values
          const updatedProducts = selectedProducts.map((product) => ({
            ...product,
            last_price:
              lastMrpsDataObject[product.product_sku] || product.last_price,
          }));

          // Update the selectedProducts state with new prices
          setSelectedProducts(updatedProducts);
          // console.log(updatedProducts);
        })
        .catch((error) => {
          console.error("Error fetching updated prices:", error);
        });
    } catch (error) {
      console.error("Error fetching updated prices:", error);
    }
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (p) => p.product_sku === product.product_sku
      );

      if (isSelected) {
        // Remove product from selectedProducts
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        // Add product to selectedProducts
        setLast_price((prevLastMrps) => ({
          ...prevLastMrps,
          [product.product_sku]: product.last_price,
        }));
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 1,
      }));
    }
  };

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const handleButtonClick = () => {
    window.open("/add_product", "_blank");
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const [openBarcode, setOpenBarcode] = useState(false);

  const handleClickOpenBarcode = () => {
    setOpenBarcode(true);
  };

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  const handleSelectedProducts = (products) => {
    setSelectedProducts(products);
  };

  const handleDiscountChange = (e, product, type) => {
    const { value } = e.target;
    const sku = product.product_sku;
    const originalMRP = product.mrp; // Use the original MRP from the product
    const currentMRP = mrps[sku] || originalMRP;
    let newMRP;

    // Parse and validate the discount value
    const parseValue = (val) => parseFloat(val) || 0;

    if (type === "percentage") {
      // Handle percentage discount
      const percentageValue = parseValue(value);
      if (percentageValue < 0 || percentageValue > 100) {
        // Invalid percentage
        return;
      }
      newMRP = originalMRP - (originalMRP * percentageValue) / 100;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: percentageValue,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: Math.ceil(originalMRP - newMRP),
      }));
    } else if (type === "numeric") {
      // Handle numeric discount
      const numericValue = parseFloat(value) || 0;
      if (numericValue < 0 || numericValue > currentMRP) {
        // Invalid numeric discount
        return;
      }
      newMRP = currentMRP - numericValue;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${Math.ceil((numericValue / originalMRP) * 100)}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: Math.ceil(numericValue),
      }));
    }

    // Ensure MRP is not negative
    newMRP = Math.max(newMRP, 0);

    // Update MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [sku]: Math.ceil(newMRP),
    }));
  };

  const handleMRPChange = (event, product) => {
    const { value } = event.target;
    const updatedMRP = parseInt(value, 10);

    // Retrieve the original MRP and current discount for the product
    const originalMRP = product.mrp;
    const discount = discounts[product.product_sku] || 0;

    // If MRP is less than or equal to 0, reset MRP and discounts to 0
    if (updatedMRP <= 0 || value === "") {
      setMrps((prevMrps) => ({
        ...prevMrps,
        [product.product_sku]: 0,
      }));
      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [product.product_sku]: 0,
      }));
      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [product.product_sku]: 0,
      }));
      return 0;
    }

    // Update the MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: updatedMRP,
    }));

    // Handle flat discount
    const flatDiscount = parseFloat(discount);
    const newNumericDiscount = flatDiscount;
    const discountedPrice = Math.max(updatedMRP - newNumericDiscount, 0);

    // Calculate the discount based on the original MRP
    const calculatedPercentageDiscount =
      ((originalMRP - updatedMRP) / originalMRP) * 100;
    const calculatedNumericDiscount = originalMRP - updatedMRP;

    let discountratenumeric = 0;
    if (updatedMRP > originalMRP) {
      discountratenumeric = 0;
    } else {
      discountratenumeric = Math.ceil(calculatedNumericDiscount);
    }

    // Update the state with the recalculated discounts
    setNumericDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountratenumeric,
    }));

    let discountrate = 0;
    if (updatedMRP > originalMRP) {
      discountrate = 0;
    } else {
      discountrate = calculatedPercentageDiscount.toFixed(2);
    }
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountrate,
    }));

    return discountedPrice;
  };

  const calculateProductTotal = (product) => {
    const quantity = quantities[product.product_sku] || 1;
    const mrp = mrps[product.product_sku] || product.mrp;

    if (mrp <= 0) {
      return 0;
    }

    // Retrieve the discount value and check if it contains '%'

    return Math.ceil(mrp * quantity);
  };

  const calculateTotalMRP = () => {
    return selectedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] || totalamount;
      return total + productMRP * (quantities[product.product_sku] || 1);
    }, 0);
  };

  const handleSubmit = async () => {
    const saleData = {
      customer_pk: parseInt(customer) || 0,
      sale_date: sale_date,
      shipping_pk: parseInt(address),
      discount_amount: discount_amount || 0,
      user_name: userDetails.name || "",
      products: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]) || 1,
        mrp: mrps[product.product_sku] || product.mrp,
        item_discount: parseFloat(numericDiscounts[product.product_sku]) || 0.0,
      })),
    };

    axios
      .post(create_sale, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess("Sale created successfully!");
          setSelectedProducts([]);
          setProducts([]);
          setDiscount("0.00");
          setSearchInput("");
          setS_date("");
          setCustomer("");
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setOpenError(true);
        setError("Sale failed!", error);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
      />

      <BarcodeProducts
        openBarcode={openBarcode}
        handleCloseBarcode={handleCloseBarcode}
        onSelectedProducts={handleSelectedProducts}
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        quantities={quantities}
        setMrps={setMrps}
        setQuantities={setQuantities}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <h3 className="page-title">Add Sale</h3>

          <div className="row my-4">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-4 my-2" style={{ display: "flex" }}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel id="customer_name">
                        Select Customer
                      </InputLabel>
                      <Select
                        labelId="customer_name"
                        label="Select customer"
                        value={customer}
                        onChange={handleCustomerChange}
                        // onChange={(e) => setCustomer(e.target.value)}
                      >
                        <MenuItem value="">--Select--</MenuItem>

                        {customers.map((cust) => (
                          <MenuItem key={cust.id} value={cust.id}>
                            {cust.customer_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: "2px", height: "4.4vh" }}
                      onClick={handleOpencustomer}
                      size="small"
                    >
                      Add
                    </Button>
                  </div>
                  <div className="col-lg-2 my-2">
                    <TextField
                      type="date"
                      value={sale_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setS_date(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-6 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    {customer ? (
                      <>
                        <FormControl fullWidth size="small" required>
                          <InputLabel id="customer_name_address">
                            Shipping Address
                          </InputLabel>
                          <Select
                            labelId="customer_name_address"
                            label="Shipping Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          >
                            {customeraddress.map((cust) => (
                              <MenuItem key={cust.id} value={cust.id}>
                                {cust.contact_name}
                                <br />
                                {cust.address},<br /> {cust.state},{" "}
                                {cust.pincode}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className="card my-2 "
                style={{ padding: "20px 15px 2px 15px" }}
              >
                <div className="row">
                  <div className="col-8">
                    <TextField
                      onChange={handleInputChange}
                      value={searchInput}
                      fullWidth
                      size="small"
                      id="search-products"
                      label="Search Products"
                      variant="outlined"
                    />
                    {displaySearch ? (
                      <>
                        <div
                          id="searchList"
                          ref={searchListRef}
                          style={{
                            border: "1px solid #dedede",
                            position: "absolute",
                            zIndex: "2",
                            marginTop: "3px",
                            maxHeight: "50vh",
                            overflowY: "scroll",

                            backgroundColor: "white",
                            width: "92.4%",
                          }}
                        >
                          {products.map((product, index) => (
                            <>
                              <div
                                className="card p-3"
                                style={{ display: "flex" }}
                                key={index}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isProductSelected(product)}
                                      onChange={() =>
                                        handleCheckboxChange(product)
                                      }
                                      name="check"
                                    />
                                  }
                                  label={
                                    product.product_sku +
                                    " : " +
                                    product.product_name
                                  }
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* <div className="col-2 ">
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      onClick={handleClickOpenBarcode}
                      size="small"
                    >
                      Scan Barcode
                    </Button>
                  </div> */}
                  <div className="col-2 ">
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      size="small"
                      onClick={handleButtonClick}
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                <h5>Sale Summary</h5>
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">Sub-total</div>
                  <div
                    className="col-6 my-2"
                    style={{ textAlign: "right", color: "red" }}
                  >
                    ₹{calculateTotalMRP().toFixed(2)}
                  </div>

                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <input
                      placeholder="0.00"
                      value={discount_amount || discountedprice}
                      onChange={(e) => setDiscount(e.target.value)}
                      style={{
                        border: "none",
                        width: "100%",
                        textAlign: "right",
                      }}
                    />
                  </div>
                  <div className="container my-3">
                    <Button
                      variant="outlined"
                      style={{ width: "100%" }}
                      onClick={handleSubmit}
                    >
                      Update Sale
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <div className="products_table_class">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>

                          <TableCell align="left">MRP</TableCell>
                          <TableCell align="left">Discount</TableCell>
                          <TableCell align="left">Price</TableCell>
                          {/* <TableCell align="right">Last Price</TableCell> */}

                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  style={{ width: "6.5vw" }}
                                  scope="row"
                                >
                                  {product.product_sku || product.sku}
                                </TableCell>
                                <TableCell align="left">
                                  {product.product_name || product.name}
                                </TableCell>

                                <TableCell align="left">
                                  ₹{product.mrp}.00
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ width: "10vw" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <OutlinedInput
                                      size="small"
                                      style={{ width: "5vw" }}
                                      name="discountPercentage"
                                      // value={
                                      //   product.is_from_detail === 1
                                      //     ? Math.ceil(
                                      //         (product.discount_amount /
                                      //           product.mrp || product.mrp) *
                                      //           100
                                      //       )
                                      //     : discounts[product.product_sku]
                                      // }
                                      value={
                                        discounts[product.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      name="discountValue"
                                      value={
                                        numericDiscounts[product.product_sku] ||
                                        0
                                      }
                                      // value={
                                      //   numericDiscounts[product.product_sku] ||
                                      //   product.discount_amount
                                      // }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "numeric"
                                        )
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                    />
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ width: "9vw" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      type="text"
                                      value={
                                        mrps[product.product_sku] || product.mrp
                                      }
                                      onChange={(e) =>
                                        handleMRPChange(e, product)
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {product.is_from_detail !== 1 &&
                                    product.last_price != product.mrp ? (
                                      <>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          on="click"
                                          pinned
                                          content={`₹${product.last_price}.00`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </TableCell>

                                {/* <TableCell align="right">
                                  ₹{product.last_price}.00
                                </TableCell> */}

                                <TableCell
                                  align="right"
                                  style={{ width: "6vw" }}
                                >
                                  <TextField
                                    type="number"
                                    size="small"
                                    value={quantities[product.product_sku] || 1}
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    variant="outlined"
                                  />
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ width: "5vw", fontWeight: "bold" }}
                                >
                                  ₹{calculateProductTotal(product).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "2vw" }}
                                >
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      handleDeleteProduct(product.product_sku)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan="9"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSale;
