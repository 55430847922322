import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail } from "../../Api";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import CustomToolbar from "../../Components/CustomToolbar";
import PrintBarcode from "./PrintBarcode";
import Button from "@mui/material/Button";

function BarcodePurchase() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [openBarcode, setOpenBarcode] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const { id } = useParams();

  const handleClickOpenBarcode = () => {
    setOpenBarcode(true);
  };

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = () => {
    axios
      .get(fetch_purchase_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          purchase_pk: parseInt(id),
        },
      })
      .then((response) => {
        setSelectedProducts(response.data.purchase_detail[0].items_detail);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const columns = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 120,
    },

    {
      field: "reference_number",
      headerName: "Ref Number",
      width: 180,
    },

    {
      field: "product_name",
      headerName: "Product",
      width: 620,
    },

    {
      field: "quantity",
      headerName: "Qty",
      width: 100,
    },
    // {
    //   field: "product_sku",
    //   headerName: "Barcode",
    //   width: 150,
    //   renderCell: (params) => {
    //     <Barcode
    //       width={1}
    //       height={40}
    //       fontSize={12}
    //       margin={2}
    //       value={params.row.product_sku}
    //     />;
    //   },
    // },
  ];

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <PrintBarcode
        openBarcode={openBarcode}
        handleCloseBarcode={handleCloseBarcode}
        // onSelectedProducts={handleSelectedProducts}
        // setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        // quantities={quantities}
        // setMrps={setMrps}
        // setQuantities={setQuantities}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <h3 className="page-title">Purchase Barcode</h3>
          <Button
            style={{ float: "right", marginTop: "-22px" }}
            size="small"
            variant="contained"
            onClick={handleClickOpenBarcode}
          >
            Print
          </Button>

          <div className="row my-4">
            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <DataGrid
                  slots={{ toolbar: CustomToolbar }}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rows={selectedProducts}
                  columns={columns}
                  // filterMode="server"
                  // onFilterModelChange={handleFilterModelChange}
                  // loading={loading}
                  // paginationMode="server"
                  // paginationModel={{
                  //   pageSize: pageSize,
                  //   page: currentPage - 1,
                  // }}
                  // rowCount={totalPages * pageSize}
                  // onPaginationModelChange={handlePaginationModelChange}
                  // selectionModel={selectionModel}
                  // onSelectionModelChange={(newSelection) =>
                  //   setSelectionModel(newSelection)
                  // }
                />
                {/* <div className="products_table_class">
          
                      <TableBody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          selectedProducts.map((product, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                          
                        
                              <TableCell align="left">
                                {product.quantity}
                              </TableCell>
                              <TableCell align="right">
                                <Barcode
                                  width={1}
                                  height={40}
                                  fontSize={12}
                                  margin={2}
                                  value={product.product_sku}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan="7"
                              style={{ textAlign: "center", color: "red" }}
                            >
                              No Products Selected
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarcodePurchase;
