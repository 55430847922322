import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AddNewBin from "./AddNewBin";
import EditBinModal from "./EditBinModal";
import DeleteBinModal from "../../../Messages/DeleteWarehouse/DeleteBin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1000px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function BinsUpdateModal({
  setOpenBinModal,
  openBinModal,
  bins,
  row_name,
  rack_id,
  rack_name,
  setBins,
}) {
  const handleClose = () => {
    setOpenBinModal(false);
  };

  const [openAddBinModal, setOpenAddBinModal] = useState(false);

  const handleAddBin = () => {
    setOpenAddBinModal(true);
  };

  const [bin_id, setBinId] = useState("");
  const [openDeleteBin, setOpenDeleteBin] = useState(false);

  const handleDeleteBin = (binid) => {
    setBinId(binid);
    setOpenDeleteBin(true);
  };

  const [openEditBin, setOpenEditBin] = useState(false);
  const [binname, setBinName] = useState("");
  const handleEditBin = (binid, binname) => {
    setBinId(binid);
    setBinName(binname);
    setOpenEditBin(true);
  };

  return (
    <>
      <AddNewBin
        openAddBinModal={openAddBinModal}
        setOpenAddBinModal={setOpenAddBinModal}
        rack_id={rack_id}
        setBins={setBins}
      />

      <EditBinModal
        openEditBin={openEditBin}
        setOpenEditBin={setOpenEditBin}
        binname={binname}
        bin_id={bin_id}
        setBins={setBins}
        rack_id={rack_id}
        // ware_id={id}
        // warehouse={warehouse}
        // fetchData={fetchData}
      />

      <DeleteBinModal
        openDeleteBin={openDeleteBin}
        setOpenDeleteBin={setOpenDeleteBin}
        bin_id={bin_id}
        rack_id={rack_id}
        setBins={setBins}
      />

      <Modal
        open={openBinModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Bins {rack_name}
          </Typography>

          <Button
            variant="outlined"
            style={{ float: "right", marginTop: "-3vh" }}
            size="small"
            onClick={() => handleAddBin()}
          >
            Add New Bin
          </Button>

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
              marginTop: "2vh",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5px" }}>#</TableCell>
                    <TableCell align="left">Rack Name</TableCell>
                    <TableCell align="left">Bin Name</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bins &&
                    bins.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "5px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ width: "33%", color: "#bababa" }}
                        >
                          {rack_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          {row.bin_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          <Button
                            onClick={() => handleEditBin(row.id, row.bin_name)}
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="info"
                            size="small"
                          >
                            Edit
                          </Button>
                          {/* <Button
                            onClick={() => handleDeleteBin(row.id)}
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="error"
                            size="small"
                          >
                            Delete
                          </Button> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default BinsUpdateModal;
