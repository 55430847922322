import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail, fetch_inventory } from "../../Api";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import AssignWare from "./AssignWarehouseModal";

function GoodAllocation() {
  const [openModal, setOpenModal] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [inventory, setInventory] = useState([]);

  const [sku, setSku] = useState("");
  const [qty, setQty] = useState("");

  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");

  // const [openDelete, setOpenDelete] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = () => {
    axios
      .get(fetch_purchase_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          purchase_pk: parseInt(id),
        },
      })
      .then((response) => {
        // console.log(response);
        setSelectedProducts(response.data.purchase_detail[0].items_detail);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let unallocatedQty = 0;
  
  const handleEditClick = (product) => {
    unallocatedQty = product.quantity - product.allocated_quantity;
    setSku(product.product_sku);
    setQty(unallocatedQty);
    setOpenModal(true);

    const inventory_data = {
      sku: product.product_sku,
      purchase_pk: parseInt(id),
    };
    axios
      .post(fetch_inventory, inventory_data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          // console.log(response.data.inventory);
          setInventory(response.data.inventory);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });

    // Open the AssignWare modal here if you want
  };

  return (
    <>
      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        sku={sku}
        qty={qty}
        initialInventory={inventory}
        fetchPurchases={fetchPurchases}
        purchase_pk={id}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <h3 className="page-title">Allocate Goods</h3>

          <div className="row my-4">
            <div className="col-lg-12">
              <div className="card p-4">
                <div className="products_table_class">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>
                          <TableCell align="left">Purchased Qty</TableCell>
                          <TableCell align="left">Allocated Qty</TableCell>
                          <TableCell align="right">Allocated Status</TableCell>
                          <TableCell align="left">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  style={{ width: "6.5vw" }}
                                  scope="row"
                                >
                                  {product.product_sku}
                                </TableCell>
                                <TableCell align="left">
                                  {product.product_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "8vw" }}
                                >
                                  {product.quantity}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "8vw" }}
                                >
                                  {product.allocated_quantity}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ width: "9vw" }}
                                >
                                  {product.allocated_quantity === 0 ? (
                                    <>
                                      <Chip
                                        size="small"
                                        label="Pending"
                                        color="error"
                                        variant="outlined"
                                      />
                                    </>
                                  ) : product.quantity ===
                                    product.allocated_quantity ? (
                                    <>
                                      <Chip
                                        size="small"
                                        label="Completed"
                                        color="success"
                                        variant="container"
                                      />
                                    </>
                                  ) : (
                                    <Chip
                                      size="small"
                                      label="Partially Completed"
                                      color="warning"
                                      variant="outlined"
                                    />
                                  )}
                                </TableCell>

                                {/* <TableCell
                                  align="left"
                                  style={{ maxWidth: "18vw" }}
                                >
                                  <Autocomplete
                                    disablePortal
                                    size="small"
                                    options={warehousesOptions}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        style={{ width: "100%" }}
                                        {...params}
                                        label="Warehouse"
                                      />
                                    )}
                                  />
                                </TableCell> */}

                                <TableCell
                                  align="left"
                                  style={{ maxWidth: "12vw" }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleEditClick(product)}
                                  >
                                    Edit
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GoodAllocation;
