import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  fetchProductsForTransfer,
  fetchProductInven,
  TransferInventory,
} from "../../../Api";
import { debounce } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import { FaPencilAlt } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AssignWare from "./Inventory_ProductsModal";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import CustomToolbar from "../../../Components/CustomToolbar";

function AddStockTransfer() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [sourcewarehouse, setSourceWarehouse] = useState(
    userDetails.primary_warehouse_pk
  );
  const [warehouse, setWarehouse] = useState("");

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };

  const fetchProducts = useCallback(() => {
    axios
      .get(fetchProductsForTransfer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: searchQuery,
          warehouse_id: parseInt(sourcewarehouse),
        },
      })
      .then((response) => {
        // console.log(response);
        const fetchedProducts = response.data.data;
        setProducts(fetchedProducts);
        setDisplay(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    []
  );

  // Trigger the fetchProducts function when searchQuery changes
  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [p_sku, setSKU] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [inventory, setInven] = useState([]);
  const [unallocated, setUnallocated] = useState(0);

  const handleInven = (sku) => {
    setOpenModal(true);
    setSKU(sku);

    try {
      axios
        .get(fetchProductInven, {
          params: {
            warehouse_id: parseInt(sourcewarehouse),
            sku: sku,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setInven(response.data.row_inventory);
          setUnallocated(response.data.obj.unallocated);
          setOpenModal(true);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalQuantity = (sku) => {
    const storedData = JSON.parse(localStorage.getItem("transferStock"));
    if (storedData && storedData[sku]) {
      return storedData[sku].reduce((total, item) => total + item.quantity, 0);
    }
    return 0;
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 20,
    },
    {
      field: "product_sku",
      renderCell: (params) => params.row.product_sku || "N/A",
      headerName: "SKU",
      width: 200,
    },

    {
      field: "product_name",
      renderCell: (params) => params.row.product_name || "N/A",
      headerName: "Product",
      width: 600,
    },
    {
      field: "Total Qty",
      renderCell: (params) => calculateTotalQuantity(params.row.product_sku),
      headerName: "Total",
      width: 400,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <FaPencilAlt
            style={{
              marginLeft: "20px",
              color: "cornflowerblue",
              cursor: "pointer",
            }}
            onClick={() => handleInven(params.row.product_sku)}
          />
        </>
      ),
    },
  ];

  const inven_details = JSON.parse(localStorage.getItem("transferStock"));

  const [refreshKey, setRefreshKey] = useState(0);

  const handleTransfer = () => {
    const hasValidQuantity = selectedProducts.every((product) => {
      const totalQty = calculateTotalQuantity(product.product_sku);
      return totalQty > 0; // Ensure total_qty is greater than 0
    });

    if (!hasValidQuantity) {
      setError(
        "Some products have a total quantity of 0 or are empty. Please check and try again."
      );
      setOpenError(true);
      return; // Exit the function if validation fails
    }
    let formattedData = {
      from_warehouse: parseInt(sourcewarehouse),
      to_warehouse: parseInt(warehouse),
      user_name: userDetails.name,
      note: notes,
      transfered_data: selectedProducts.map((product) => {
        const productInvenDetails = inven_details[product.product_sku] || [];

        return {
          sku: product.product_sku,
          total_qty: calculateTotalQuantity(product.product_sku),
          inventory_detail: productInvenDetails,
        };
      }),
    };

    try {
      axios
        .post(TransferInventory, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock Transferred Successfully!`);

            localStorage.removeItem("transferStock");
            setProducts([]);
            setDisplay(false);
            setSelectedProducts([]);
            setRefreshKey((prevKey) => prevKey + 1);
            setWarehouse("");
            setNotes("");
            // navigate("/stock_transfer");
          } else {
            setError(response.data.message);
            setOpenError(true);
            setDisplay(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    // Clear specific items from local storage on component mount (page load or refresh)
    localStorage.removeItem("transferStock");
  }, []);

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AssignWare
        key={refreshKey}
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        ware_house={sourcewarehouse}
        p_sku={p_sku}
        setInven={setInven}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <h3 className="page-title">Transfer Stocks</h3>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-3 p-3">
                <h5>Source Warehouse</h5>

                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Select Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Select Warehouse"
                    value={sourcewarehouse}
                    onChange={(e) => setSourceWarehouse(e.target.value)}
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-3 p-3">
                <h5>Target Warehouse</h5>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Select Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Select Warehouse"
                    required
                    value={warehouse}
                    onChange={(e) => setWarehouse(e.target.value)}
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem
                        key={ware.id}
                        disabled={ware.id === sourcewarehouse}
                        value={ware.id}
                      >
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-3 p-3">
                <TextField
                  size="small"
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={1}
                  style={{ marginTop: "27px", width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>
              <div className="col-lg-3 p-3">
                <TextField
                  key={refreshKey}
                  style={{ marginTop: "27px" }}
                  fullWidth
                  size="small"
                  label="Search Products..."
                  variant="outlined"
                  onInput={handleSearchChange}
                />
                {displaySearch ? (
                  <>
                    <div
                      id="searchList"
                      ref={searchListRef}
                      style={{
                        border: "1px solid #dedede",
                        position: "absolute",
                        zIndex: "2",
                        right: "3.5vw",
                        marginTop: "3px",
                        maxHeight: "50vh",
                        overflowY: "scroll",

                        backgroundColor: "white",
                        width: "40%",
                      }}
                    >
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProductSelected(product)}
                                  onChange={() => handleCheckboxChange(product)}
                                  name="check"
                                />
                              }
                              label={
                                product.product_sku +
                                " : " +
                                product.product_name
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className="p-3">
              <DataGrid
                slots={{ toolbar: CustomToolbar }}
                // checkboxSelection
                disableRowSelectionOnClick
                rows={selectedProducts}
                columns={columns}
              />
            </div>
          </div>
          <Button
            disabled={
              !warehouse || !selectedProducts || selectedProducts.length === 0
            }
            style={{ marginTop: "5px", float: "right" }}
            onClick={handleTransfer}
            variant="contained"
          >
            Confirm Transfer
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddStockTransfer;
