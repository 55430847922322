import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import {
  apiKey,
  fetch_warehouse,
  allocate_inventory,
  delete_single_inventory,
} from "../../Api";
import { FaTrashAlt } from "react-icons/fa";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1200px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AssignWarehouseModal({
  sku,
  setOpenModal,
  openModal,
  qty,
  purchase_pk,
  fetchPurchases,
  initialInventory,
}) {
  const [warehouses, setWarehouses] = useState([]);
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [bins, setBins] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const [success, setSuccess] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  // const [allocations, setAllocations] = useState([
  //   { warehouse: null, row: null, rack: null, bin: null, qty: "" },
  // ]);

  const [allocations, setAllocations] = useState([
    {
      warehouse: null,
      row: { options: [], selected: null },
      rack: { options: [], selected: null },
      bin: { options: [], selected: null },
      qty: "",
    },
  ]);

  useEffect(() => {
    if (openModal) {
      fetchWarehouses();
    }
  }, [openModal]);
  // console.log(inventory);

  const deleteInventoryRow = (id) => {
    const data_delete = {
      inv_pk: id,
    };

    axios
      .post(delete_single_inventory, data_delete, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(response.data.message);

          // setOpenModal(false);
          fetchPurchases();
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
        resetStates();
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });

    // console.log("Submitting allocations:", allocations);
    setOpenModal(false);
  };

  useEffect(() => {
    // Reset the allocations with the last selected values
    if (openModal) {
      setAllocations((prevAllocations) => {
        return prevAllocations.map((allocation, index) => {
          const warehouse = warehouses.find(
            (ware) => ware.id === allocation.warehouse?.id
          );
          const row = warehouse?.rows.find(
            (row) => row.id === allocation.row?.id
          );
          const rack = row?.racks.find(
            (rack) => rack.id === allocation.rack?.id
          );
          const bin = rack?.bins.find((bin) => bin.id === allocation.bin?.id);

          return {
            ...allocation,
            warehouse: warehouse || null,
            row: row || null,
            rack: rack || null,
            bin: bin || null,
          };
        });
      });
    }
  }, [openModal, warehouses]);

  const fetchWarehouses = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleWarehouseSelection = (index, warehouseId) => {
    const selectedWarehouse = warehouses.find(
      (ware) => ware.id === warehouseId
    );
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      warehouse: selectedWarehouse,
      row: null,
      rack: null,
      bin: null,
    };
    setAllocations(newAllocations);
    setRows(selectedWarehouse ? selectedWarehouse.rows : []);
    setRacks([]);
    setBins([]);
  };

  const handleRowSelection = (index, rowId) => {
    const selectedRow = rows.find((row) => row.id === rowId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      row: selectedRow,
      rack: null,
      bin: null,
    };
    setAllocations(newAllocations);
    setRacks(selectedRow ? selectedRow.racks : []);
    setBins([]);
  };

  const handleRackSelection = (index, rackId) => {
    const selectedRack = racks.find((rack) => rack.id === rackId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      rack: selectedRack,
      bin: null,
    };
    setAllocations(newAllocations);
    setBins(selectedRack ? selectedRack.bins : []);
  };

  const handleBinSelection = (index, binId) => {
    const selectedBin = bins.find((bin) => bin.id === binId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      bin: selectedBin,
    };
    setAllocations(newAllocations);
  };

  const handleQtyChange = (index, qtyValue) => {
    const numericQty = Math.floor(Number(qtyValue));
    if (numericQty < 0 || isNaN(numericQty)) {
      setErrorr("Quantity must be a positive number.");
      return;
    }
    const totalQty = allocations.reduce(
      (acc, allocation) => acc + Number(allocation.qty || 0),
      0
    );

    if (numericQty + totalQty - (Number(allocations[index].qty) || 0) <= qty) {
      const newAllocations = [...allocations];
      newAllocations[index] = {
        ...newAllocations[index],
        qty: numericQty,
      };
      setAllocations(newAllocations);
      setErrorr(""); // Clear error if valid
    } else {
      setErrorr("Total quantity exceeds the allowed limit.");
    }
  };

  const addAllocationRow = () => {
    setAllocations([
      ...allocations,
      { warehouse: null, row: null, rack: null, bin: null, qty: "" },
    ]);
  };

  const deleteAllocationRow = (index) => {
    const newAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(newAllocations);
  };

  const handleSubmit = () => {
    // Map the inventory data to the required format
    const mappedInventory = initialInventory.map((item) => ({
      inventory_pk: parseInt(item.id),
      warehouse_id: item.ware_house,
      row_id: item.row_ref ? item.row_ref.id : 0,
      rack_id: item.rack ? item.rack.id : 0,
      bin_id: item.bin_ref ? item.bin_ref.id : 0,
      qty: parseInt(item.quantity), // Assuming there's a `qty` field in the inventory items
    }));

    // Filter and map the new allocations
    const filteredAllocations = allocations
      .filter((allocation) => allocation.warehouse && allocation.qty > 0)
      .map((allocation) => ({
        inventory_pk: 0,
        warehouse_id: allocation.warehouse.id,
        row_id: allocation.row ? allocation.row.id : 0,
        rack_id: allocation.rack ? allocation.rack.id : 0,
        bin_id: allocation.bin ? allocation.bin.id : 0,
        qty: parseInt(allocation.qty),
      }));

    // Combine the existing inventory and new allocations
    const combinedAllocations = [...mappedInventory, ...filteredAllocations];

    const data_Send = {
      sku,
      user_name: userDetails.name,
      purchase_pk: parseInt(purchase_pk),
      allocations: combinedAllocations,
    };

    axios
      .post(allocate_inventory, data_Send, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(response.data.message);
          setOpenModal(false);
          fetchPurchases();
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
        resetStates();
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );

        setOpenError(true);
      });

    // console.log("Submitting allocations:", allocations);
    // setOpenModal(false);
  };

  // Function to reset the states
  const resetStates = () => {
    setAllocations([
      { warehouse: null, row: null, rack: null, bin: null, qty: "" },
    ]);
    setWarehouses([]);
    setRows([]);
    setRacks([]);
    setBins([]);
    setError("");
    setSuccess("");
  };

  const handleClose = () => {
    setOpenModal(false);
    setAllocations([
      { warehouse: null, row: null, rack: null, bin: null, qty: "" },
    ]);
    setError(""); // Clear error on close
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Allocate Inventory for <b>{sku}</b>
          </Typography>

          <p style={{ color: "red" }}>Unallocated Quantity: {qty}</p>
          {initialInventory.map((data, index) => (
            <div className="row my-1 remove_warehouse_div" key={index}>
              <div className="col-lg-4">
                <TextField
                  readOnly
                  value={
                    warehouses.find((ware) => ware.id === data.ware_house)
                      ?.ware_house || ""
                  }
                  size="small"
                  fullWidth
                />
              </div>
              <div className="col-lg-2">
                <TextField
                  value={data.row_ref ? data.row_ref.row_name : ""}
                  size="small"
                  fullWidth
                />
              </div>
              <div className="col-lg-2">
                <TextField
                  value={data.rack ? data.rack.rack_name : ""}
                  size="small"
                  fullWidth
                />
              </div>
              <div className="col-lg-2">
                <TextField
                  value={data.bin_ref ? data.bin_ref.bin_name : ""}
                  size="small"
                  fullWidth
                />
              </div>
              <div className="col-lg-1">
                <TextField value={data.quantity} size="small" fullWidth />
              </div>
              <div className="col-lg-1">
                <FaTrashAlt
                  style={{
                    fontSize: "18px",
                    marginLeft: "10px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  className="delete-inv-btn"
                  onClick={() => deleteInventoryRow(data.id)}
                />
              </div>
            </div>
          ))}
          <hr />
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
            }}
          >
            {allocations.map((allocation, index) => (
              <div className="row my-1 add_warehouse_div" key={index}>
                <div className="col-lg-4">
                  <Autocomplete
                    size="small"
                    options={warehouses.map((ware) => ({
                      label: ware.ware_house,
                      value: ware.id,
                    }))}
                    value={
                      allocation.warehouse
                        ? {
                            label: allocation.warehouse.ware_house,
                            value: allocation.warehouse.id,
                          }
                        : null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleWarehouseSelection(index, newValue.value);
                      } else {
                        handleWarehouseSelection(index, null);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        {...params}
                        label="Warehouse"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-2">
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={[
                      ...rows.map((row) => ({
                        label: row.row_name,
                        value: row.id,
                      })),
                    ]}
                    value={
                      allocation.row
                        ? {
                            label: allocation.row.row_name,
                            value: allocation.row.id,
                          }
                        : null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleRowSelection(index, newValue.value);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    } // Add this line
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        {...params}
                        label="Row"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-2">
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={[
                      ...racks.map((rack) => ({
                        label: rack.rack_name,
                        value: rack.id,
                      })),
                    ]}
                    value={
                      allocation.rack
                        ? {
                            label: allocation.rack.rack_name,
                            value: allocation.rack.id,
                          }
                        : null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleRackSelection(index, newValue.value);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    } // Add this line
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        {...params}
                        label="Rack"
                      />
                    )}
                  />
                </div>
                <div className="col-lg-2">
                  <Autocomplete
                    disablePortal
                    size="small"
                    options={[
                      ...bins.map((bin) => ({
                        label: bin.bin_name,
                        value: bin.id,
                      })),
                    ]}
                    value={
                      allocation.bin
                        ? {
                            label: allocation.bin.bin_name,
                            value: allocation.bin.id,
                          }
                        : null
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        handleBinSelection(index, newValue.value);
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    } // Add this line
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{ width: "100%" }}
                        {...params}
                        label="Bin"
                      />
                    )}
                  />
                </div>

                <div className="col-lg-1">
                  <TextField
                    size="small"
                    style={{ width: "100%" }}
                    label="Qty"
                    required
                    value={allocation.qty}
                    onChange={(e) => handleQtyChange(index, e.target.value)}
                    error={Boolean(errorr)}
                    helperText={
                      errorr && (index === allocations.length - 1 ? errorr : "")
                    }
                  />
                </div>
                <div className="col-lg-1">
                  <IoIosAddCircle
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                    onClick={addAllocationRow}
                  />
                  {allocations.length > 1 && (
                    <FaTrashAlt
                      style={{
                        fontSize: "18px",
                        marginLeft: "10px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteAllocationRow(index)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "10px",
            }}
          >
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AssignWarehouseModal;
