import React, { useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import {
  apiKey,
  fetch_sale_detail,
  allocateinventory_Stock_Out,
} from "../../Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "450px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PrintSlipModal({
  setOpenModal,
  openModal,
  data,
  addressdata,
  saleProducts,
}) {
  const handleClose = () => {
    setOpenModal(false);
  };

  const invoiceRef = useRef(null);
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=1200");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
#invoice-POS {
  padding: 2mm;
  margin: 0 auto;
  width: 80mm;
  color: black;
  background: #fff;


  #top,
  #mid,
  #bot {
    border-bottom: 1px solid #000;
  }
    

  #bot {
    min-height: 50px;
  }


  .info {
    display: block;

    margin-left: 0;
    margin-top: 5px;
  }
  .title {
    float: right;
  }
  .title p {
    text-align: right;
  }
  table {
    width: 100%;
    border-collapse: collapse;
      font-weight:bold;
  }

  .tabletitle {
    font-size: 10px;
    font-weight:bold;
    background: #eee;
    padding-top: 10px;
  }
  .service {
  
    padding-top:5px;
     margin-top:5px  }
  .item {
    width: 5mm;
  }
  .itemtext {
    font-size: 0.5em;
  }

  #legalcopy {
    margin-top: 5mm;
  }
}

#estDiv{
margin-top:-22px
}

#billaddress{
margin-top:-10px
}

.pricediv{
margin-top:-25px
}

            }
            @page {
              size: auto;
              margin: 10mm;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${invoiceRef.current.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Slip
          </Typography> */}

          <div id="invoice-POS" ref={invoiceRef}>
            <center id="top">
              <div className="info">
                <h1 style={{ fontSize: "20px", fontFamily: "sans-serif" }}>
                  ESTIMATE
                </h1>
              </div>
            </center>
            <div style={{ backgroundColor: "black", height: "1px" }}></div>
            <div id="mid">
              <div className="info">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                      ESTIMATE No.:
                    </h3>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                      KTT-0000{data.id}
                    </h3>
                  </div>
                </div>

                <div
                  id="estDiv"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                      ESTIMATE Date:
                    </h3>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                      {data.sale_date}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div id="mid">
              <div className="info">
                <h3 style={{ fontSize: "12px", fontFamily: "sans-serif" }}>
                  To
                </h3>
                <p
                  id="billaddress"
                  style={{
                    fontSize: "11px",
                    marginTop: "-9px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {addressdata.customer_name}
                </p>
              </div>
            </div>

            <div id="bot">
              <div id="table">
                <table>
                  <tr
                    className="tabletitle"
                    style={{
                      fontSize: "11px",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    <td className="item">SN</td>
                    <td className="Hours">Name</td>
                    <td className="Rate">Qty</td>
                    <td className="Rate">Unit Price</td>
                    <td className="Rate">Amount</td>
                  </tr>

                  {saleProducts.map((data, index) => (
                    <>
                      <tr
                        className="service"
                        style={{ fontSize: "11px", fontFamily: "sans-serif" }}
                      >
                        <td className="tableitem">
                          <p className="itemtext">
                            <b>{index + 1}</b>
                          </p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            <b>{data.name}</b>
                          </p>
                        </td>
                        <td className="tableitem">
                          <p
                            className="itemtext"
                            style={{ textAlign: "center" }}
                          >
                            <b>{data.quantity}</b>
                          </p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            <b>₹{data.price}</b>
                          </p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            <b>₹{data.quantity * data.price}</b>
                          </p>
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h5 style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}>
                    Discount:
                  </h5>
                </div>
                <div style={{ textAlign: "right" }}>
                  <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                    ₹
                    {data.discount_amount
                      ? data.discount_amount.toFixed(2)
                      : 0.0}
                  </h3>
                </div>
              </div>

              <div
                className="pricediv"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <h5 style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}>
                    Shipping Charge:
                  </h5>
                </div>
                <div style={{ textAlign: "right" }}>
                  <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                    ₹
                    {data.courier_charge ? data.courier_charge.toFixed(2) : 0.0}
                  </h3>
                </div>
              </div>

              <div
                className="pricediv"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <h5 style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}>
                    Other Charges:
                  </h5>
                </div>
                <div style={{ textAlign: "right" }}>
                  <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                    ₹{data.other_charge ? data.other_charge.toFixed(2) : 0.0}
                  </h3>
                </div>
              </div>

              <div style={{ backgroundColor: "black", height: "1px" }}></div>
              <div
                className="pricediv"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <div>
                  <h5 style={{ fontSize: "11.5px", fontFamily: "sans-serif" }}>
                    Total:
                  </h5>
                </div>
                <div style={{ textAlign: "right" }}>
                  <h3 style={{ fontSize: "13px", fontFamily: "sans-serif" }}>
                    ₹
                    {data.total_amount
                      ? (
                          data.total_amount +
                          data.other_charge +
                          data.courier_charge -
                          data.discount_amount
                        ).toFixed(2)
                      : 0.0}
                  </h3>
                </div>
              </div>

              <p style={{ fontSize: "11px" }}>
                {data.note ? <span>Note: {data.note}</span> : null}
              </p>

              <div id="legalcopy" style={{ marginTop: "10px" }}>
                <strong style={{ fontSize: "11px", fontFamily: "sans-serif" }}>
                  Terms & Conditions
                </strong>
                <p
                  className="legal"
                  style={{ fontSize: "9.5px", fontFamily: "sans-serif" }}
                >
                  This is a computer generated receipt not a valid tax document.
                  <br />
                  There is no guarantee/warranty.
                  <br />
                  No return only exchange.
                </p>
              </div>
            </div>
          </div>

          <Button
            style={{ marginTop: "8px", marginLeft: "5px", float: "right" }}
            onClick={handlePrint}
            variant="outlined"
          >
            Print
          </Button>
          <Button
            style={{ marginTop: "8px", float: "right" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default PrintSlipModal;
