import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { apiKey, adjustmentHostory } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Delete from "../../../Messages/Delete";
import CustomToolbar from "../../../Components/CustomToolbar";
import Error from "../../../Messages/Error";

function Adjustments() {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      selectedBrands: [],
      selectedTypes: [],
      selectedStocks: [],
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(adjustmentHostory, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setWarehouses(response.data.data);
        setTotalPages(response.data.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (sku) => {
    navigate(`/update-product/${sku}`);
  };

  const [skus, setSKus] = useState([]);

  const handleDeleteClick = () => {
    const selectedSKUs = [];
    const ourDiv = document.querySelector(
      ".MuiDataGrid-main.css-3eek4p-MuiDataGrid-main"
    );
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_sk = third_child.innerHTML;
          selectedSKUs.push(selected_sk);
        }
      }
    });

    setSKus(selectedSKUs);

    if (selectedSKUs.length === 0) {
      setError("No products selected, please choose items to continue!");
      setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }

    // Perform the delete action
  };

  const columns = [
    {
      field: "id",
      headerName: "Reference",
      width: 200,
      renderCell: (params) =>
        "ADJUST-" + params.row.id.toString().padStart(5, "0"),
    },

    { field: "date", headerName: "Adjustment Date", width: 220 },

    {
      field: "action",
      headerName: "Stock Type",
      width: 180,
      renderCell: (params) => (
        <Chip
          size="small"
          style={{ textTransform: "capitalize" }}
          label={params.row.action}
          color={
            params.row.action == "stock in"
              ? "success"
              : params.row.action == "stock out"
              ? "error"
              : "primary"
          }
          variant="outlined"
        />
      ),
    },

    {
      field: "note",
      headerName: "Notes",
      width: 400,
      renderCell: (params) => (params.row.note ? params.row.note : "-"),
    },
    { field: "total_products", headerName: "Products", width: 150 },
    { field: "user_name", headerName: "User", width: 250 },
  ];

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Delete
        selectedSKUs={skus}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      {/* <ImportProducts
        openImport={openImport}
        setOpenImport={setOpenImport}
        fetchData={fetchData}
      /> */}

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Inventory
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Stock Adjustments
            </Typography>
          </Breadcrumbs>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-adjustment")}
                >
                  New Stock Adjustment
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              disableRowSelectionOnClick
              rows={warehouses}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Adjustments;
