import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_row } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditRowModal({
  setOpenEditRowModal,
  openEditRowModal,
  row_name,
  row_id,
  fetchData,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [newrow, setRowName] = useState(row_name);

  useEffect(() => {
    if (row_name) {
      setRowName(row_name);
    }
  }, [row_name]);

  const handleClose = () => {
    setOpenEditRowModal(false);
    setError("");
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    if (!newrow) {
      toast.error("Row cannot be empty!");
      return;
    }

    const trimmedRowname = newrow.trim();
    if (trimmedRowname.length < 2) {
      setError("Row name must be at least 2 characters long.");
      setOpenError(true);
      return;
    }

    const formattedData = {
      row_pk: parseInt(row_id),
      row_name: newrow,
    };

    axios
      .post(edit_row, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          setOpenSuccess(true);
          setSuccess(`Row has been updated successfully!`);
          setRowName("");
          fetchData();
          setOpenEditRowModal(false);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openEditRowModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            style={{ marginBottom: "15px" }}
          >
            Update Row :<b style={{ color: "black" }}>{row_name}</b>
          </Typography>

          <div
            style={{
              maxHeight: "30vh",
            }}
          >
            <TextField
              id="outlined-row-name"
              label="Row Name"
              value={newrow}
              size="small"
              required
              onChange={(e) => setRowName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              disabled={newrow == row_name}
              onClick={handleAddRow}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Update Row
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditRowModal;
