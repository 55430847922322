import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { delete_products, apiKey } from "../Api";
import Success from "./Success";
import Error from "./Error";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const style = {
  width: 550,
};

function Delete({ openDelete, setOpenDelete, selectedSKUs, fetchData }) {
  const handleClose = () => {
    setOpenDelete(false);
  };

  const [uniqueNumber, setUniqueNumber] = useState(null);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const generateUniqueNumber = () => {
      const newNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit number
      setUniqueNumber(newNumber);
    };

    generateUniqueNumber(); // Generate number on component mount
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setTimer(60); // Reset timer to 60 seconds
          setUniqueNumber(Math.floor(1000 + Math.random() * 9000)); // Generate a new number
        } else {
          return prevTimer - 1; // Decrement the timer
        }
      });
    }, 1000); // Update timer every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [uniqueNumber]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [otp, setOtp] = useState("");

  const handleDelete = () => {
    if (otp == uniqueNumber) {
      axios
        .delete(delete_products, {
          headers: {
            "API-Key": apiKey,
          },
          data: {
            all_skus: selectedSKUs,
          },
        })
        .then(() => {
          setOpenSuccess(true);
          setSuccess(`Selected Products Deleted Successfully!`);
          setOpenDelete(false);
          fetchData();
          setOtp("");
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message);
          console.log(error.response.data.message);
          setOtp("");

          // console.error("Failed to delete products", error);
        });
    } else {
      setOpenError(true);
      setError("Oops! OTP doesnot matched");
      setOtp("");
    }
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
              <div
                className="card p-3 my-3"
                style={{ border: "1px solid #e3e3e3", borderRadius: "3px" }}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <h3
                      style={{
                        letterSpacing: "4px",
                        textAlign: "center",
                        marginTop: "6px",
                        // alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {uniqueNumber !== null ? (
                        <span style={{ color: "black" }}>{uniqueNumber}</span>
                      ) : (
                        <span style={{ color: "grey" }}>Generating...</span>
                      )}

                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginLeft: "10px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        ({timer} seconds)
                      </span>
                    </h3>
                  </div>
                  <div className="col-lg-6">
                    <TextField
                      id="outlined-basic"
                      label="Enter OTP"
                      size="small"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="outlined" color="error">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default Delete;
