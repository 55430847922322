import React from "react";
import { Link } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { MdInventory } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";

function Sidebar() {
  return (
    <>
      <nav
        className="sidebar sidebar-offcanvas"
        id="sidebar"
        style={{ backgroundColor: "##00265c" }}
      >
        <ul className="nav">
          <li className="nav-item nav-category">Main</li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <span className="icon-bg">
                {/* <i className="mdi mdi-cube menu-icon"></i> */}
                <MdSpaceDashboard />
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#products"
              aria-expanded="false"
              aria-controls="forms"
            >
              <span className="icon-bg">
                {/* <i className="mdi mdi-format-list-bulleted menu-icon"></i> */}
                <AiOutlineProduct />
              </span>
              <span className="menu-title">Products</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="products">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/all_products">
                    All Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/add_product">
                    Add Products
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#inventory"
              aria-expanded="false"
              aria-controls="forms"
            >
              <span className="icon-bg">
                {/* <i className="mdi mdi-format-list-bulleted menu-icon"></i> */}
                <MdInventory />
              </span>
              <span className="menu-title">Inventory</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="inventory">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/warehouses">
                    Warehouses
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/stock_adjustments">
                    Adjustments
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/conversions">
                    Conversion
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/stock_transfer">
                    Stock Transfer
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#purchase"
              aria-expanded="false"
              aria-controls="forms"
            >
              <span className="icon-bg">
                {/* <i className="mdi mdi-format-list-bulleted menu-icon"></i> */}
                <BiSolidPurchaseTag />
              </span>
              <span className="menu-title">Purchases</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="purchase">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/all_purchases">
                    Purchase Orders
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/stock_transfer">
                    Drafts
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#sales"
              aria-expanded="false"
              aria-controls="forms"
            >
              <span className="icon-bg">
                {/* <i className="mdi mdi-format-list-bulleted menu-icon"></i> */}
                <BiSolidPurchaseTag />
              </span>
              <span className="menu-title">Sales</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="sales">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className="nav-link" to="/all_sales">
                    Sales
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link className="nav-link" to="/stock_transfer">
                    Drafts
                  </Link>
                </li> */}
              </ul>
            </div>
          </li>

          {/* <li className="nav-item nav-category">Products</li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <span className="icon-bg">
                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              </span>
              <span className="menu-title">Master Products</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/ui-features/buttons.html">
                    Buttons
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link"
                    href="pages/ui-features/dropdowns.html"
                  >
                    Dropdowns
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link"
                    href="pages/ui-features/typography.html"
                  >
                    Typography
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#icons"
              aria-expanded="false"
              aria-controls="icons"
            >
              <span className="icon-bg">
                <i className="mdi mdi-contacts menu-icon"></i>
              </span>
              <span className="menu-title">Icons</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="icons">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/icons/mdi.html">
                    Material
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#forms"
              aria-expanded="false"
              aria-controls="forms"
            >
              <span className="icon-bg">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              </span>
              <span className="menu-title">Forms</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="forms">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link"
                    href="pages/forms/basic_elements.html"
                  >
                    Form Elements
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item nav-category">Data Representation</li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#charts"
              aria-expanded="false"
              aria-controls="charts"
            >
              <span className="icon-bg">
                <i className="mdi mdi-chart-bar menu-icon"></i>
              </span>
              <span className="menu-title">Charts</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="charts">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/charts/chartjs.html">
                    ChartJs
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#tables"
              aria-expanded="false"
              aria-controls="tables"
            >
              <span className="icon-bg">
                <i className="mdi mdi-table-large menu-icon"></i>
              </span>
              <span className="menu-title">Tables</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="tables">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/tables/basic-table.html">
                    Basic Table
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item nav-category">Sample Pages</li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#auth"
              aria-expanded="false"
              aria-controls="auth"
            >
              <span className="icon-bg">
                <i className="mdi mdi-lock menu-icon"></i>
              </span>
              <span className="menu-title">User Pages</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="auth">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/samples/blank-page.html">
                    {" "}
                    Blank Page{" "}
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/samples/login.html">
                    {" "}
                    Login{" "}
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/samples/register.html">
                    {" "}
                    Register{" "}
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/samples/error-404.html">
                    {" "}
                    404{" "}
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a className="nav-link" href="pages/samples/error-500.html">
                    {" "}
                    500{" "}
                  </a>
                </li>
              </ul>
            </div>
          </li> */}

          {/* <li className="nav-item sidebar-user-actions">
            <div className="user-details">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex align-items-center">
                    <div className="sidebar-profile-img">
                      <img
                        src="../../../assets/images/faces/face28.png"
                        alt="image"
                      />
                    </div>
                    <div className="sidebar-profile-text">
                      <p className="mb-1">Henry Klein</p>
                    </div>
                  </div>
                </div>
                <div className="badge badge-danger">3</div>
              </div>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="#" className="nav-link">
                <i className="mdi mdi-weather-sunny menu-icon"></i>
                <span className="menu-title">Settings</span>
              </a>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="#" className="nav-link">
                <i className="mdi mdi-speedometer menu-icon"></i>
                <span className="menu-title">Take Tour</span>
              </a>
            </div>
          </li>
          <li className="nav-item sidebar-user-actions">
            <div className="sidebar-user-menu">
              <a href="#" className="nav-link">
                <i className="mdi mdi-logout menu-icon"></i>
                <span className="menu-title">Log Out</span>
              </a>
            </div>
          </li> */}
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
