import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetchProductInven } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1000px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Inventory_ProductsModal({
  setOpenModal,
  openModal,
  inventory,
  p_sku,
  setInven,
  ware_house,
  unallocated,
  setUnallocated,
}) {
  const [error, setError] = useState("");
  const [quantities, setQuantities] = useState({});

  const handleClose = () => {
    setOpenModal(false);

    setError("");
  };

  useEffect(() => {
    fetchDataa();
  }, []);

  useEffect(() => {
    const savedQuantities = JSON.parse(localStorage.getItem("transferStock"));
    if (savedQuantities && savedQuantities[p_sku]) {
      setQuantities(
        savedQuantities[p_sku].reduce((acc, curr) => {
          acc[curr.pk] = curr;
          return acc;
        }, {})
      );
    }
  }, [openModal, p_sku, inventory]);

  const fetchDataa = () => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: ware_house,
          sku: p_sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setInven(response.data.row_inventory);
        setUnallocated(response.data.obj.unallocated);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleQuantityChange = (id, value) => {
    const quantity = parseInt(value) || 0;

    // Find the available quantity for the given id
    const availableQuantity =
      inventory.find((item) => item.id === id)?.quantity || unallocated;

    // Check if the quantity entered exceeds the available quantity
    if (quantity > availableQuantity) {
      setError(
        `Quantity cannot exceed available quantity of ${availableQuantity}.`
      );
      return;
    }

    // Clear any previous error
    setError("");
    // Update the quantities state
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: { pk: id, quantity: value },
    }));

    const updatedSelectedProducts =
      JSON.parse(localStorage.getItem("transferStock")) || {};

    if (!updatedSelectedProducts[p_sku]) {
      updatedSelectedProducts[p_sku] = [];
    }

    const existingProductIndex = updatedSelectedProducts[p_sku].findIndex(
      (product) => product.pk === id
    );

    if (existingProductIndex >= 0) {
      updatedSelectedProducts[p_sku][existingProductIndex].quantity =
        parseInt(value);
    } else {
      updatedSelectedProducts[p_sku].push({
        pk: parseInt(id),
        quantity: parseInt(value),
      });
    }

    localStorage.setItem(
      "transferStock",
      JSON.stringify(updatedSelectedProducts)
    );
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Inventory
            </Typography>
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Transfer Stock
            </Typography>

            <Typography sx={{ color: "text.primary" }}>#{p_sku}</Typography>
          </Breadcrumbs>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ backgroundColor: "rgb(229, 246, 253)" }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Row</TableCell>
                  <TableCell align="left">Rack</TableCell>
                  <TableCell align="left">Bin</TableCell>
                  <TableCell align="left">Available</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unallocated && unallocated > 0 ? (
                  <>
                    <TableRow
                      style={{ backgroundColor: "#ffe6db" }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        colSpan={5}
                        style={{ color: "red" }}
                      >
                        UnAllocated Inventory:
                        <span style={{ color: "black" }}>
                          {unallocated} UnAllocated Products
                        </span>
                      </TableCell>

                      <TableCell align="right" style={{ width: "10vw" }}>
                        <TextField
                          label="Quantity"
                          size="small"
                          value={quantities[0]?.quantity || ""}
                          onChange={(e) =>
                            handleQuantityChange(0, e.target.value)
                          }
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
                {inventory.length > 0 ? (
                  <>
                    {inventory &&
                      inventory.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {row.row_ref ? row.row_ref.row_name : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.rack ? row.rack.rack_name : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.bin_ref ? row.bin_ref.bin_name : "-"}
                          </TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="right" style={{ width: "10vw" }}>
                            <TextField
                              label="Quantity"
                              size="small"
                              value={quantities[row.id]?.quantity || ""}
                              onChange={(e) =>
                                handleQuantityChange(row.id, e.target.value)
                              }
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                ) : (
                  <>
                    <TableRow>
                      <TableCell
                        colSpan="6"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No allocated products found
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {error && (
            <Typography color="error" style={{ marginTop: "8px" }}>
              {error}
            </Typography>
          )}

          <Button
            style={{ marginTop: "8px", float: "right" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default Inventory_ProductsModal;
