import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { import_product, apiKey } from "../../Api";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";

function ImportProductsModal({ openImport, setOpenImport, fetchData }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [data, setData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);

  const handleClose = () => {
    setOpenImport(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setFileSelected(false);
      return;
    }

    if (
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls
        "text/csv", // .csv
      ].includes(file.type)
    ) {
      setFileSelected(false); // Ensure the file is not marked as selected
      setOpenError(true);
      setError("Only .xls or .csv file format are allowed");

      // Reset the file input field
      e.target.value = "";
      return;
    }

    setFileSelected(true);

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0]; // First row as headers
      const products = jsonData.slice(1).map((row) => {
        const product = {};
        headers.forEach((header, index) => {
          product[header] = row[index];
        });
        return product;
      });

      setData(products);
    };

    reader.readAsArrayBuffer(file);
  };

  // console.log(data);

  const handleImportProduct = () => {
    if (!fileSelected) {
      setError("Please select a file before uploading.");
      setOpenError(true);
      return;
    }

    axios
      .post(import_product, data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.message);
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(
            `File uploaded Successfully. All Products Added Succeesfully!`
          );
          fetchData();
          setOpenImport(false);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        if (error.response.data.status === 3) {
          setError("Product SKU Already Exists! ");
          setOpenError(true);
        } else {
          setError(error.response.data.message);
          setOpenError(true);
        }
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openImport} onClose={handleClose}>
        <DialogTitle>Import Products</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
           
          </DialogContentText> */}
          <TextField
            type="file"
            onChange={handleFileChange}
            required
            accept=".xls,.csv" // Restrict file types to .xls and .csv
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleImportProduct} disabled={!fileSelected}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImportProductsModal;
