import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function InvenModal({ setOpenModal, openModal, unallocated, invenDetails }) {
  return (
    <>
      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Allocate Inventory for <b style={{ color: "black" }}>{warehouse}</b>
          </Typography> */}

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
            }}
          >
            {invenDetails &&
              invenDetails.map((inven, index) => (
                <div key={index} className="row my-1 add_warehouse_div">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-3">
                    <h5>{inven.rack_ref ? inven.rack_ref.rack_name : null}</h5>
                    {/* <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...racks.map((rack) => ({
                          label: rack.rack_name,
                          value: rack.id,
                        })),
                      ]}
                      value={
                        allocation.rack
                          ? {
                              label: allocation.rack.rack_name,
                              value: allocation.rack.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleRackChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Rack"
                        />
                      )} 
                    />*/}
                  </div>
                  <div className="col-lg-3">
                    {/* <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...bins.map((bin) => ({
                          label: bin.bin_name,
                          value: bin.id,
                        })),
                      ]}
                      value={
                        allocation.bin
                          ? {
                              label: allocation.bin.bin_name,
                              value: allocation.bin.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleBinChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Bin"
                        />
                      )}
                    /> */}
                  </div>

                  <div className="col-lg-2">
                    {/* <TextField
                      size="small"
                      type="number"
                      label="Quantity"
                      value={allocation.qty}
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                      fullWidth
                      helperText={
                        errorr &&
                        (index === allocations.length - 1 ? errorr : "")
                      }
                    /> */}
                  </div>
                </div>
              ))}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              // onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              // onClick={handleSubmit}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default InvenModal;
