import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { delete_bin, fetch_bins, apiKey } from "../../Api";
import Success from "../Success";
import Error from "../Error";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";

const style = {
  width: 550,
};

function DeleteBin({
  openDeleteBin,
  setOpenDeleteBin,
  rack_id,
  bin_id,
  setBins,
}) {
  const handleClose = () => {
    setOpenDeleteBin(false);
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = () => {
    axios
      .delete(delete_bin, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          bin_pk: parseInt(bin_id),
        },
      })
      .then(() => {
        setOpenSuccess(true);
        setSuccess(`Selected Bin Deleted Successfully!`);
        try {
          axios
            .get(fetch_bins, {
              params: {
                rack_pk: parseInt(rack_id),
              },
              headers: {
                "API-Key": apiKey,
              },
            })
            .then((response) => {
              setBins(response.data.bins);
            })
            .catch((error) => {
              setError(error);
            });
        } catch (error) {
          console.log(error);
        }
        setOpenDeleteBin(false);
      })
      .catch((error) => {
        setOpenError(true);
        setError("Oops!", error);

        console.error("Failed to delete row", error);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openDeleteBin} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteBin;
