import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { apiKey, fetch_all_product } from "../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";
import Button from "@mui/material/Button";
import { ImRadioChecked } from "react-icons/im";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function BarcodeProducts({
  handleCloseBarcode,
  openBarcode,
  onSelectedProducts,
  selectedProducts,
  setSelectedProducts,
  quantities,
  setQuantities,
  setMrps,
}) {
  const [products, setProducts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [noProductError, setNoProductError] = useState(false);
  const [lastActivitySku, setLastActivitySku] = useState(null);
  const [newlyAddedSku, setNewlyAddedSku] = useState(null); // New state for newly added SKU
  const rowRefs = useRef({});

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value.trim().length >= 2) {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);

        const matchedProduct = fetchedProducts.find(
          (product) => product.product_sku === value
        );

        if (matchedProduct) {
          handleProductSelect(matchedProduct);
        } else {
          setSearchInput("");
          setNoProductError(true);
          setTimeout(() => {
            setNoProductError(false);
          }, 200);
        }
      } catch (error) {
        setProducts([]);
      }
    } else {
      setProducts([]);
    }
  };

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
    setQuantities((prevQuantities) => {
      const { [product_sku]: _, ...rest } = prevQuantities;
      return rest;
    });
    setLastActivitySku(null); // Clear the last activity highlight when a product is deleted
    setNewlyAddedSku(null); // Clear newly added SKU highlight
  };

  const handleProductSelect = (product) => {
    setSelectedProducts((prevSelected) => {
      const existingProductIndex = prevSelected.findIndex(
        (p) => p.product_sku === product.product_sku
      );

      if (existingProductIndex >= 0) {
        const updatedProducts = [...prevSelected];
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [product.product_sku]: (prevQuantities[product.product_sku] || 0) + 1,
        }));
        updatedProducts[existingProductIndex].barcode_scan = 1; // Set barcode_scan to 1 for existing product
        setLastActivitySku(product.product_sku);
        return updatedProducts;
      } else {
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [product.product_sku]: 1,
        }));
        setNewlyAddedSku(product.product_sku); // Set newly added SKU
        // Add new product with barcode_scan set to 1
        return [...prevSelected, { ...product, barcode_scan: 1 }];
      }
    });

    setSearchInput("");
    setProducts([]);
  };

  const handleQuantityChange = (index, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [selectedProducts[index].product_sku]: quantity > 0 ? quantity : 1,
    }));
    setLastActivitySku(selectedProducts[index].product_sku); // Highlight the last modified SKU
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    if (pasteData.length > 10) {
      event.preventDefault();
      const truncatedData = pasteData.substring(0, 10);
      setSearchInput(truncatedData);
    }
  };

  useEffect(() => {
    if (lastActivitySku && rowRefs.current[lastActivitySku]) {
      rowRefs.current[lastActivitySku].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [lastActivitySku]);

  useEffect(() => {
    if (newlyAddedSku && rowRefs.current[newlyAddedSku]) {
      rowRefs.current[newlyAddedSku].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // setTimeout(() => setNewlyAddedSku(null), 3000); // Clear the highlight after 3 seconds
    }
  }, [newlyAddedSku]);

  const handleMRPChange = (event, product) => {
    const { value } = event.target;
    const updatedMRP = parseFloat(value);

    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: isNaN(updatedMRP) ? 0 : updatedMRP,
    }));
  };

  return (
    <div>
      <Modal
        open={openBarcode}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseBarcode();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add products
          </Typography>
          <div className="row">
            <div className="col-md-4 my-2">
              <TextField
                onChange={handleInputChange}
                onPaste={handlePaste}
                value={searchInput}
                fullWidth
                size="small"
                id="search-products"
                label="Search Products"
                variant="outlined"
                inputProps={{ maxLength: 10 }} // Restrict input length to 10
              />
            </div>
            <div className="col-md-4">
              {noProductError ? (
                <>
                  <ImRadioChecked
                    style={{
                      color: "green",
                      marginTop: "17px",
                      fontSize: "20px",
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "50vh", overflowY: "scroll", marginTop: "2px" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                style={{
                  position: "sticky",
                  top: "0px",
                  backgroundColor: "white",
                  zIndex: "3",
                }}
              >
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell align="left">Product</TableCell>

                  <TableCell align="left">MRP</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProducts && selectedProducts.length > 0 ? (
                  selectedProducts.map((product, index) => (
                    <TableRow
                      key={product.product_sku}
                      ref={(el) => (rowRefs.current[product.product_sku] = el)}
                      sx={{
                        display:
                          product.barcode_scan === 1 ? "table-row" : "none",
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor:
                          product.product_sku === lastActivitySku
                            ? "rgba(0, 123, 255, 0.1)" // Highlight color for the last activity
                            : product.product_sku === newlyAddedSku
                            ? "rgba(0, 255, 0, 0.1)" // Highlight color for newly added SKU
                            : "inherit",
                      }}
                    >
                      <TableCell
                        component="th"
                        style={{ width: "6.5vw" }}
                        scope="row"
                      >
                        {product.product_sku}
                      </TableCell>
                      <TableCell align="left">{product.product_name}</TableCell>

                      <TableCell align="left">
                        <TextField
                          style={{ maxWidth: "25vw" }}
                          size="small"
                          value={product.mrp || ""}
                          onChange={(e) => handleMRPChange(e, product)}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="right" style={{ maxWidth: "7vw" }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          type="number"
                          value={quantities[product.product_sku] || 1} // Use quantities state
                          onChange={(e) => {
                            const quantity = parseInt(e.target.value, 10) || 1;
                            handleQuantityChange(index, quantity);
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <FaTrashAlt
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() =>
                            handleDeleteProduct(product.product_sku)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan="6"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      No Products Selected
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px" }}
              onClick={handleCloseBarcode}
              variant="outlined"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default BarcodeProducts;
