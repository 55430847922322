import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_product_detail,
  getTypeBrand,
  update_product,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { Form } from "semantic-ui-react";
import Button from "@mui/material/Button";
import { MdDoubleArrow } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function AddProducts() {
  const { sku } = useParams();
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [getsku, setGetSKU] = useState("");
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);

  const [product_name, setProduct_name] = useState("");
  const [oem_number, setOEM] = useState("");
  const [part_number, setPartNo] = useState("");
  const [product_brand, setProductBrand] = useState("");
  const [product_type, setProductType] = useState("");
  const [product_desc, setDesc] = useState("");
  const [mrp, setMrp] = useState("");
  const [in_stock, setInstock] = useState("");
  const [stock_alert, setStockAlert] = useState(1);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [hsn_code, setHSN] = useState("");
  const [gst_percent, setGST] = useState("");

  useEffect(() => {
    fetchProduct();
    fetchBrandType();
  }, [sku]);

  const fetchProduct = () => {
    axios
      .get(fetch_product_detail, {
        params: {
          product_sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.product[0].product_name);
        setGetSKU(response.data.product[0].product_sku);
        setProduct_name(response.data.product[0].product_name);
        setOEM(response.data.product[0].oem_number);
        setPartNo(response.data.product[0].part_number);
        setProductBrand(response.data.product[0].brand__product_brand);
        setProductType(response.data.product[0].product_type__product_type);
        setDesc(response.data.product[0].description);
        setMrp(response.data.product[0].mrp);
        setInstock(response.data.product[0].in_stock);
        setStockAlert(response.data.product[0].stock_alert);
        setLength(response.data.product[0].length);
        setBreadth(response.data.product[0].breadth);
        setHeight(response.data.product[0].height);
        setWeight(response.data.product[0].weight);
        setHSN(response.data.product[0].hsn_code);
        setGST(response.data.product[0].gst_percent);
      })
      .catch((error) => {
        console.log(error);
        setError("Error fetching product details.");
      });
  };

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.product_types);
        setTypes(response.data.product_types);
        setBrands(response.data.product_brands);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
      });
  };

  const handleAddProduct = () => {
    let formattedData = {
      product_sku: getsku,
      product_name: product_name,
      oem_number: oem_number,
      part_number: part_number,
      product_brand: product_brand,
      product_type: product_type,
      description: product_desc,
      length: length,
      breadth: breadth,
      height: height,
      weight: weight,
      mrp: parseFloat(mrp),
      // in_stock: parseInt(in_stock) || 0,
      stock_alert: parseInt(stock_alert),
      hsn_code: hsn_code,
      gst_percent: gst_percent,
    };

    axios
      .post(update_product, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(
            `Product with SKU: ${getsku} has been updated successfully!`
          );
          fetchProduct();
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
        fetchProduct();
      });
  };
  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              All Products
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Update Product
            </Typography>
          </Breadcrumbs>

          <Form className="forms-sample" onSubmit={handleAddProduct}>
            <div className="row">
              <div className="col-lg-8 my-2">
                <div className="card">
                  <div className="card-body">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Product
                      Details
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-3 my-3">
                        <TextField
                          id="outlined-basic"
                          label="Product SKU (Not Editable)"
                          value={getsku}
                          size="small"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-9 my-3">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Product Name"
                          value={product_name}
                          size="small"
                          required
                          style={{ width: "100%" }}
                          onChange={(e) => setProduct_name(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-3">
                        <TextField
                          id="outlined-select-type"
                          select
                          required
                          size="small"
                          style={{ width: "100%" }}
                          label="Product Type"
                          value={product_type}
                          onChange={(e) => setProductType(e.target.value)}
                        >
                          <MenuItem value="">--Select--</MenuItem>
                          {types.map((type) => (
                            <MenuItem key={type.id} value={type.product_type}>
                              {type.product_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="col-lg-6 my-3">
                        <TextField
                          id="outlined-select-brand"
                          select
                          required
                          style={{ width: "100%" }}
                          size="small"
                          label="Product Brand"
                          value={product_brand}
                          onChange={(e) => setProductBrand(e.target.value)}
                        >
                          <MenuItem value="">--Select--</MenuItem>
                          {brands.map((brand) => (
                            <MenuItem
                              key={brand.id}
                              value={brand.product_brand}
                            >
                              {brand.product_brand}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-multiline-partnumber"
                          label="Part Number"
                          multiline
                          style={{ width: "100%" }}
                          size="small"
                          value={part_number}
                          onChange={(e) => setPartNo(e.target.value)}
                          rows={4}
                        />
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-multiline-oemnumber"
                          label="OEM Number"
                          multiline
                          style={{ width: "100%" }}
                          size="small"
                          value={oem_number}
                          onChange={(e) => setOEM(e.target.value)}
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card my-3">
                  <div className="card-body">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Product
                      Description
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Description"
                          multiline
                          style={{ width: "100%" }}
                          size="small"
                          value={product_desc}
                          onChange={(e) => setDesc(e.target.value)}
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-2">
                <div className="card">
                  <div className="card-body">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Inventory
                      & Price
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-stock"
                          label="Stock"
                          value={in_stock}
                          size="small"
                          style={{ width: "100%" }}
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-stock-alert"
                          label="Stock Alert"
                          value={stock_alert}
                          size="small"
                          style={{ width: "100%" }}
                          onChange={(e) => setStockAlert(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-12 my-2">
                        <TextField
                          id="outlined-multiline-mrp"
                          label="MRP"
                          value={mrp}
                          size="small"
                          required
                          style={{ width: "100%" }}
                          onChange={(e) => setMrp(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-hsn"
                          label="HSN"
                          value={hsn_code}
                          size="small"
                          style={{ width: "100%" }}
                          onChange={(e) => setHSN(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="GST"
                          id="outlined-start-gst"
                          style={{ width: "100%" }}
                          size="small"
                          value={gst_percent}
                          onChange={(e) => setGST(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card my-3">
                  <div className="card-body">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Dimensions
                      & Weight
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Length"
                          id="outlined-start-length"
                          style={{ width: "100%" }}
                          size="small"
                          value={length}
                          onChange={(e) => setLength(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Breadth"
                          id="outlined-start-breadth"
                          style={{ width: "100%" }}
                          size="small"
                          value={breadth}
                          onChange={(e) => setBreadth(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Height"
                          id="outlined-start-height"
                          style={{ width: "100%" }}
                          size="small"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Weight"
                          id="outlined-start-weight"
                          style={{ width: "100%" }}
                          size="small"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                kg
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <br />
                      <hr />
                      <div className="container text-center my-2">
                        {!product_desc &&
                        !product_brand &&
                        !product_type &&
                        !in_stock &&
                        !mrp ? (
                          <>
                            <Button
                              variant="outlined"
                              endIcon={<SendIcon />}
                              disabled
                              style={{ cursor: "pointer" }}
                            >
                              Update Product
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              endIcon={<SendIcon />}
                              type="submit"
                            >
                              Update Product
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      ;
    </>
  );
}

export default AddProducts;
