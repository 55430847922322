import React, { useState } from "react";
import axios from "axios";
import { apiKey, fetch_bins } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignBin from "./BinsUpdateModal";
import AddRackModal from "./AddNewRack";
import DeleteRackModal from "../../../Messages/DeleteWarehouse/DeleteRack";
import EditRackModal from "./EditRackModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RacksUpdateModal({
  setOpenModal2,
  openModal2,
  racks,
  row_name,
  row_id,
  setRacks,
}) {
  const handleClose = () => {
    setOpenModal2(false);
  };

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [openAddRackModal, setOpenAddRackModal] = useState(false);
  const [openBinModal, setOpenBinModal] = useState(false);

  const handleAddRack = () => {
    setOpenAddRackModal(true);
  };

  const [rack_id, setRackId] = useState("");
  const [rack_name, setRackname] = useState("");
  const [bins, setBins] = useState([]);

  const handleBinClick = (rackid, rack_name) => {
    setRackId(rackid);
    setRackname(rack_name);
    setOpenBinModal(true);
    // console.log(rackid, "is rack pk");

    try {
      axios
        .get(fetch_bins, {
          params: {
            rack_pk: parseInt(rack_id),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setBins(response.data.bins);
          // console.log(response);
          // setRow(row);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [openDeleteRack, setOpenDeleteRack] = useState(false);
  const handleDeleteRack = (rackid) => {
    setRackId(rackid);
    setOpenDeleteRack(true);
  };

  const [openEditRack, setOpenEditRack] = useState(false);
  const handleEditRack = (rackid, rackname) => {
    setRackId(rackid);
    setRackname(rackname);
    setOpenEditRack(true);
  };

  return (
    <>
      <AssignBin
        openBinModal={openBinModal}
        setOpenBinModal={setOpenBinModal}
        rack_id={rack_id}
        setBins={setBins}
        bins={bins}
        rack_name={rack_name}
      />

      <AddRackModal
        openAddRackModal={openAddRackModal}
        setOpenAddRackModal={setOpenAddRackModal}
        row_id={row_id}
        setRacks={setRacks}
      />

      <EditRackModal
        openEditRack={openEditRack}
        setOpenEditRack={setOpenEditRack}
        rack_name={rack_name}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
        // ware_id={id}
        // warehouse={warehouse}
        // fetchData={fetchData}
      />

      <DeleteRackModal
        openDeleteRack={openDeleteRack}
        setOpenDeleteRack={setOpenDeleteRack}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
      />

      <Modal
        open={openModal2}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Racks
          </Typography>

          <Button
            variant="outlined"
            style={{ float: "right", marginTop: "-3vh" }}
            size="small"
            onClick={() => handleAddRack()}
          >
            Add New Rack
          </Button>

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
              marginTop: "2vh",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "5px" }}>#</TableCell>
                    <TableCell align="left">Row Name</TableCell>
                    <TableCell align="left">Rack Name</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {racks &&
                    racks.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "5px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ width: "33%", color: "#bababa" }}
                        >
                          {row_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          {row.rack_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              handleBinClick(row.id, row.rack_name)
                            }
                          >
                            View
                          </Button>
                          <Button
                            onClick={() =>
                              handleEditRack(row.id, row.rack_name)
                            }
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="info"
                            size="small"
                          >
                            Edit
                          </Button>
                          {/* <Button
                            onClick={() => handleDeleteRack(row.id)}
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="error"
                            size="small"
                          >
                            Delete
                          </Button> */}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RacksUpdateModal;
