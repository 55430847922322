import React, { useState } from "react";
import axios from "axios";
import { apiKey, add_warehouse, check_warehouse } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { MdDoubleArrow } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function AddWarehouse() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [warehouse, setWarehouse] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const handleAddWarehouse = (e) => {
    e.preventDefault();

    const formattedData = {
      warehouse: warehouse,
      address: address,
    };

    axios
      .post(add_warehouse, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(`A new warehouse has been added successfully!`);
          setWarehouse("");
          setAddress("");
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Inventory
            </Typography>
            <Typography sx={{ color: "text.primary" }}>Row Rack</Typography>
          </Breadcrumbs>

          <div className="row my-4">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-body">
                  <h5>
                    <MdDoubleArrow style={{ marginTop: "-4px" }} /> Warehouse
                    Details
                  </h5>

                  <TextField
                    id="outlined-warehouse-name"
                    label="Warehouse Name"
                    value={warehouse}
                    size="small"
                    required
                    fullWidth
                    onChange={(e) => setWarehouse(e.target.value)}
                  />

                  <TextField
                    style={{ marginTop: "10px" }}
                    id="outlined-address"
                    label="Address"
                    multiline
                    rows={4}
                    value={address}
                    size="small"
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-7">
              <div className="card">
                <div className="card-body">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addRows}
                        onChange={(e) => setAddRows(e.target.checked)}
                      />
                    }
                    label="Add Rows"
                  />
                  <Autocomplete
                    multiple
                    disabled={!addRows}
                    id="rows"
                    value={rows}
                    onChange={(event, newValue) => setRows(newValue)}
                    // onChange={(event, newValue) => {
                    //   // Filter new values to ensure they are not empty and contain only alphabetic characters
                    //   const validValues = newValue.filter(
                    //     (value) => /^[a-zA-Z]+$/.test(value) // Allows only alphabetic characters
                    //   );
                    //   setRows(validValues);
                    // }}
                    options={[]}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={index}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        rows={2}
                        size="small"
                        variant="outlined"
                        label="Rows"
                        placeholder="Add Rows"
                      />
                    )}
                  />

                  <FormControlLabel
                    style={{ marginTop: "15px" }}
                    control={
                      <Checkbox
                        checked={addRacks}
                        onChange={(e) => setAddRacks(e.target.checked)}
                      />
                    }
                    label="Add Racks"
                  />
                  <Autocomplete
                    multiple
                    id="racks"
                    value={racks}
                    options={[]} // You can populate this array with relevant options
                    freeSolo
                    disabled={!addRacks}
                    onChange={(event, newValue) => setRacks(newValue)}
                    // onChange={(event, newValue) => {
                    //   const validValues = newValue.filter((value) =>
                    //     /^[a-zA-Z0-9]+$/.test(value)
                    //   );
                    //   setRacks(validValues);
                    // }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={index}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        rows={2}
                        size="small"
                        variant="outlined"
                        label="Racks"
                        placeholder="Add Racks"
                      />
                    )}
                  />

                  <FormControlLabel
                    style={{ marginTop: "15px" }}
                    control={
                      <Checkbox
                        checked={addBins}
                        onChange={(e) => setAddBins(e.target.checked)}
                      />
                    }
                    label="Add Bins"
                  />
                  <Autocomplete
                    multiple
                    id="bins"
                    value={bins}
                    options={[]} // You can populate this array with relevant options
                    freeSolo
                    disabled={!addBins}
                    onChange={(event, newValue) => setBins(newValue)}
                    // onChange={(event, newValue) => {
                    //   const validValues = newValue.filter((value) =>
                    //     /^[a-zA-Z0-9]+$/.test(value)
                    //   );
                    //   setBins(validValues);
                    // }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={index}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        rows={2}
                        size="small"
                        variant="outlined"
                        label="Bins"
                        placeholder="Add Bins"
                      />
                    )}
                  />
                </div>
                <div className="container" style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    onClick={handleAddWarehouse}
                    type="button"
                  >
                    Add Warehouse
                  </Button>
                </div>
                <br />
              </div>
            </div> */}
            <div className="container my-2">
              <Button
                variant="contained"
                onClick={handleAddWarehouse}
                type="button"
              >
                Add Warehouse
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddWarehouse;
