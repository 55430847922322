import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  fetchProductsForTransfer,
  fetch_all_product,
  fetchProductInven,
  bulk_Stock_In,
  bilk_Stock_Out,
} from "../../../Api";
import { useNavigate } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { debounce } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import CustomToolbar from "../../../Components/CustomToolbar";
import AssignRackModal from "./AssignStockModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function AddStockAdjustment() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProducts2, setSelectedProducts2] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const [type, setType] = useState("");
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [productInventories, setProductInventories] = useState({});
  const [unallocated, setUnallocated] = useState({});
  const [inven, setInven] = useState([]);
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);
  const [warehouses, setWarehouses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchWarehouse();
    fetchInven();
  }, []);

  const fetchInven = (sku) => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const { row_inventory, obj } = response.data;

        // Update inventory for the specific SKU
        setProductInventories((prevInventories) => ({
          ...prevInventories,
          [sku]: row_inventory,
        }));

        // Update unallocated quantities for the specific SKU
        setUnallocated((prev) => ({
          ...prev,
          [sku]: obj.unallocated,
        }));
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses); // Set all warehouses
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = useCallback(() => {
    if (type == 1) {
      try {
        axios
          .post(
            fetch_all_product,
            { search: searchQuery },
            {
              headers: {
                "Content-Type": "application/json",
                "API-Key": apiKey,
              },
            }
          )
          .then((response) => {
            const fetchedProducts = response.data.data.products;
            console.log(response);
            setProducts(fetchedProducts);
            setDisplay(true);
          });
        // console.log(response.data.data.products);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else if (type == 2) {
      axios
        .get(fetchProductsForTransfer, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            search: searchQuery,
            warehouse_id: parseInt(warehouse),
          },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setDisplay(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [searchQuery, warehouse]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    []
  );

  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    const newEntry = {
      ...product,
      inventoryDetail: "",
      quantity: 0,
      id: Date.now(), // Unique ID
    };

    if (type == 2) {
      setSelectedProducts((prevSelected) => {
        // Fetch inventory details for the newly added product
        fetchInven(product.product_sku);
        return [...prevSelected, newEntry];
      });
    } else if (type == 1) {
      setSelectedProducts2((prevSelected) => {
        if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
          return prevSelected.filter(
            (p) => p.product_sku !== product.product_sku
          );
        } else {
          fetchInven(product.product_sku);
          return [...prevSelected, newEntry];
        }
      });
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (productId, newValue) => {
    const numericValue = Number(newValue);
    const selectedProduct = selectedProducts.find((p) => p.id === productId);

    if (selectedProduct) {
      const selectedInventory = selectedProduct.inventoryDetail;
      const selectedInven =
        productInventories[selectedProduct.product_sku] || [];
      const inventory = selectedInven.find(
        (inv) => inv.id === selectedInventory
      );

      const availableQuantity = inventory?.quantity || 0;

      // Check if the idType is 2 and quantity exceeds available amount
      if (selectedProduct.idType == 2 && numericValue > availableQuantity) {
        alert("Quantity cannot exceed the available amount.");
        return; // Prevent updating state if validation fails
      }

      // Update the selected product or reset quantities if idType changes
      setSelectedProducts((prevSelected) =>
        prevSelected.map((product) =>
          product.id === productId
            ? {
                ...product,
                quantity:
                  selectedProduct.idType !== product.idType ? 0 : numericValue,
                idType: product.idType,
              }
            : product
        )
      );
    }
  };

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    // Update the type state
    setType(newType);
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleDelete = (id) => {
    // Filter out the row with the matching id
    const updatedRows = selectedProducts.filter((row) => row.id !== id);
    setSelectedProducts(updatedRows);
  };

  const stockout = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 550,
    },
    {
      field: "inventoryDetail",
      headerName: "Inventory",
      width: 450,
      renderCell: (params) => {
        const inventories = productInventories[params.row.product_sku] || [];
        const unallocatedQuantity = unallocated[params.row.product_sku] || 0;

        // Get selected inventories for the current SKU
        const selectedInventories = selectedProducts
          .filter((product) => product.product_sku === params.row.product_sku)
          .map((product) => product.inventoryDetail);

        // const selectedProduct = selectedProducts.find(
        //   (p) => p.product_sku === params.row.product_sku
        // );
        // const selectedInventory = selectedProduct?.inventoryDetail;
        // const selectedInven = productInventories[params.row.product_sku] || [];
        // const inventory = selectedInven.find(
        //   (inv) => inv.id === selectedInventory
        // );
        // const availableQuantity = inventory?.quantity || 0;

        return (
          <FormControl fullWidth size="small" style={{ marginTop: "5px" }}>
            <InputLabel>Inventory</InputLabel>
            <Select
              label="Inventory"
              value={params.row.inventoryDetail || ""}
              onChange={(e) => {
                const selectedInventory = e.target.value;
                setSelectedProducts((prevSelected) =>
                  prevSelected.map((product) =>
                    product.id === params.row.id
                      ? { ...product, inventoryDetail: selectedInventory }
                      : product
                  )
                );
              }}
            >
              {/* Map over inventories */}
              {inventories.map((row) => (
                <MenuItem
                  key={row.id}
                  value={row.id}
                  disabled={selectedInventories.includes(row.id)}
                >
                  {row.row_ref ? row.row_ref.row_name : null}{" "}
                  <MdKeyboardDoubleArrowRight />
                  {row.rack ? row.rack.rack_name : null}{" "}
                  <MdKeyboardDoubleArrowRight />
                  {row.bin_ref ? row.bin_ref.bin_name : null}{" "}
                  <span style={{ color: "red" }}>({row.quantity})</span>
                </MenuItem>
              ))}
              {/* Include unallocated option if applicable */}
              {unallocatedQuantity && unallocatedQuantity > 0 && (
                <MenuItem key="0" value="0">
                  Unallocated{" "}
                  <span style={{ color: "red" }}>({unallocatedQuantity})</span>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      renderCell: (params) => {
        const isDisabled = !params.row.inventoryDetail; // Disable if no inventory is selected

        // Find the available quantity based on selected inventory
        const selectedProduct = selectedProducts.find(
          (p) => p.product_sku == params.row.product_sku
        );
        const selectedInventory = selectedProduct?.inventoryDetail;
        const selectedInven = productInventories[params.row.product_sku] || [];
        const inventory = selectedInven.find(
          (inv) => inv.id == selectedInventory
        );
        const availableQuantity = inventory?.quantity || 0;

        // Determine if the quantity is valid
        const isQuantityInvalid = params.row.quantity > availableQuantity;

        return (
          <>
            <TextField
              style={{ marginTop: "5px" }}
              size="small"
              type="number"
              label="Quantity"
              fullWidth
              value={params.row.quantity || ""}
              inputProps={{ min: 0 }}
              onChange={(e) => {
                const value = e.target.value;
                handleQuantityChange(params.row.id, value);
              }}
              disabled={isDisabled} // Conditionally disable the input
              error={isQuantityInvalid} // Show error if quantity is invalid
              helperText={
                isQuantityInvalid ? "Quantity exceeds available amount" : ""
              }
            />
          </>
        );
      },
    },
    // {
    //   field: "available",
    //   headerName: "Available",
    //   width: 120,
    //   renderCell: (params) => {
    //     const selectedProduct = selectedProducts.find(
    //       (p) => p.product_sku === params.row.product_sku
    //     );
    //     const selectedInventory = selectedProduct?.inventoryDetail;
    //     const selectedInven = productInventories[params.row.product_sku] || [];
    //     const inventory = selectedInven.find(
    //       (inv) => inv.id === selectedInventory
    //     );
    //     const availableQuantity = inventory?.quantity || 0;
    //     return (
    //       <TextField
    //         disabled
    //         style={{ marginTop: "5px" }}
    //         size="small"
    //         type="number"
    //         fullWidth
    //         value={availableQuantity}
    //       />
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <FaTrashAlt
            style={{
              marginLeft: "20px",
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => handleDelete(params.row.id)} // Pass unique identifier
          />
        </>
      ),
    },
  ];

  const [quantities, setQuantities] = useState({});
  const handleQtyChange = (productSku, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productSku]: value,
    }));
  };

  const isQuantityValid = (productSku) => {
    return quantities[productSku] && quantities[productSku] > 0;
  };

  const stockin = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 600,
    },
    {
      field: "quantiy",
      headerName: "Quantity",
      width: 200,
      renderCell: (params) => (
        <>
          <TextField
            label="Quantity"
            variant="outlined"
            size="small"
            value={quantities[params.row.product_sku] || ""}
            onChange={(e) =>
              handleQtyChange(params.row.product_sku, e.target.value)
            }
            style={{ marginTop: "5px" }}
          />
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            disabled={!isQuantityValid(params.row.product_sku)}
            onClick={() => handleEditClick(params.row.product_sku)}
          >
            Allocate
          </Button>
        </>
      ),
    },

    // Additional columns if needed
  ];
  const [stockInModal, setStockInModal] = useState(false);
  const [p_sku, setP_SKU] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);

  let existingData = localStorage.getItem("BulkStock_In");

  const [qty, setQty] = useState("");
  const handleEditClick = (sku) => {
    setP_SKU(sku);
    setStockInModal(true);
    const quantity = quantities[sku] || 0;
    setQty(quantity);
    // console.log("Editing product SKU:", sku, "with quantity:", quantity);
  };

  const handleSubmitData = () => {
    // Step 1: Check for empty quantities
    const hasEmptyQuantity = selectedProducts.some(
      (product) =>
        product.quantity === 0 ||
        product.quantity === null ||
        product.quantity === undefined
    );

    if (hasEmptyQuantity) {
      setError("Quantity cannot be empty for any product.");
      setOpenError(true);
      return;
    }
    // Step 1: Identify duplicates
    const productCounts = selectedProducts.reduce((acc, product) => {
      acc[product.product_sku] = (acc[product.product_sku] || 0) + 1;
      return acc;
    }, {});

    const formattedInventory = selectedProducts.reduce((acc, product) => {
      const existingEntry = acc.find(
        (entry) => entry.product_sku === product.product_sku
      );

      if (existingEntry) {
        const existingInventoryDetail = existingEntry.inventoryDetails.find(
          (detail) => detail.inventory_pk == product.inventoryDetail
        );

        if (existingInventoryDetail) {
          existingInventoryDetail.quantity += product.quantity;
        } else {
          existingEntry.inventoryDetails.push({
            inventory_pk: parseInt(product.inventoryDetail),
            quantity: product.quantity,
          });
        }
      } else {
        acc.push({
          product_sku: product.product_sku,
          inventoryDetails: [
            {
              inventory_pk: parseInt(product.inventoryDetail),
              quantity: product.quantity,
            },
          ],
        });
      }

      return acc;
    }, []);

    const formattedData = {
      user_name: userDetails.name,
      remark: notes,
      inventory: formattedInventory,
      warehouse_id: warehouse,
    };

    const stockInformattedData = {
      user_name: userDetails.name,
      remark: notes,
      inventory: JSON.parse(existingData),
      warehouse_id: warehouse,
    };
    // console.log(formattedData, "data to submit");

    if (type == 1) {
      axios
        .post(bulk_Stock_In, stockInformattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock In Successful!`);
            setTimeout(() => {
              setSelectedProducts([]);
              setNotes("");
              setType("");
              setInven([]);
              localStorage.removeItem("BulkStock_In");
            }, 2000);
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    } else if (type == 2) {
      axios
        .post(bilk_Stock_Out, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock Out Successful!`);
            setTimeout(() => {
              setSelectedProducts([]);
              setNotes("");
              setInven([]);
              localStorage.removeItem("BulkStock_In");
              setType("");
            }, 2000);
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    }
  };

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("BulkStock_In"); // Replace with your local storage key
    };

    clearLocalStorage(); // Clear on mount
    setInven([]);
    window.addEventListener("beforeunload", clearLocalStorage);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AssignRackModal
        openModal={stockInModal}
        setOpenModal={setStockInModal}
        warehouseId={warehouse}
        p_sku={p_sku}
        setInven={setInven}
        inven={inven}
        qty={qty}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-2)}
            >
              Inventory
            </Typography>
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Stock Adjustments
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Adjust Stocks
            </Typography>
          </Breadcrumbs>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-2 p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    value={warehouse} // Ensure this is controlled by state
                    onChange={(e) => {
                      const selectedWarehouse = warehouses.find(
                        (wh) => wh.id === e.target.value
                      );
                      if (selectedWarehouse) {
                        setWarehouse(selectedWarehouse.id);
                        setSelectedProducts([]);

                        setDisplay(false);
                        setType("");
                      }
                    }}
                    label="Warehouse"
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-2 p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">
                    Stock Type
                  </InputLabel>
                  <Select
                    labelId="stock-select-label"
                    value={type}
                    onChange={handleTypeChange} // Use the handler here
                    label="Stock Type"
                  >
                    <MenuItem value="1">Stock In</MenuItem>
                    <MenuItem value="2">Stock Out</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-3 p-3">
                <TextField
                  size="small"
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={1}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>

              <div className="col-lg-3 p-3">
                <TextField
                  key={refreshKey}
                  fullWidth
                  type="search"
                  disabled={!type}
                  size="small"
                  label="Search Products..."
                  variant="outlined"
                  onInput={handleSearchChange}
                />
                {displaySearch ? (
                  <>
                    <div
                      id="searchList"
                      ref={searchListRef}
                      style={{
                        border: "1px solid #dedede",
                        position: "absolute",
                        zIndex: "2",
                        right: "3.5vw",
                        marginTop: "3px",
                        maxHeight: "50vh",
                        overflowY: "scroll",

                        backgroundColor: "white",
                        width: "40%",
                      }}
                    >
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleCheckboxChange(product)}
                            >
                              {product.product_sku +
                                " : " +
                                product.product_name}
                            </span>
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="col-lg-2 p-3">
                <Button
                  disabled={!warehouse || !type}
                  style={{ marginTop: "5px", float: "right" }}
                  onClick={handleSubmitData}
                  variant="contained"
                >
                  Confirm Adjustment
                </Button>
              </div>
            </div>

            <div
              className="p-3"
              style={{
                height: "77vh",
                marginTop: "-15px",
                width: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              {type && type == 1 ? (
                <>
                  <DataGrid
                    disableRowSelectionOnClick
                    slots={{ toolbar: CustomToolbar }}
                    rows={selectedProducts2}
                    columns={stockin}
                    pageSize={10}
                    // checkboxSelection
                  />
                </>
              ) : (
                <>
                  <DataGrid
                    disableRowSelectionOnClick
                    slots={{ toolbar: CustomToolbar }}
                    rows={selectedProducts}
                    columns={stockout}
                    pageSize={10}
                    // checkboxSelection
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStockAdjustment;
