import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Sidebar from "./Components/Sidebar";
import AddProducts from "./Pages/Products/AddProducts";
import EditProduct from "./Pages/Products/EditProduct";
import MasterProducts from "./Pages/Products/MasterProducts";
import Login from "./Authen/Login";
import AuthTokenVerify from "./Authen/AuthTokenVerify";
import Warehouses from "./Pages/Inventory/Warehouses";
import AddWarehouse from "./Pages/Inventory/AddWarehouse";
import Purchases from "./Pages/Purchase/Purchases";
import AddPurchase from "./Pages/Purchase/AddPurchase";
import EditPurchase from "./Pages/Purchase/EditPurchase";
import GoodAllocation from "./Pages/Purchase/GoodAllocation";
import Sales from "./Pages/Sales/Sales";
import AddSale from "./Pages/Sales/AddSale";
import EditSale from "./Pages/Sales/EditSale";
import BarcodePurchase from "./Pages/Purchase/BarcodePurchase";
import RowRackDetails from "./Pages/Inventory/RowRackDetails";
import StockTransfer from "./Pages/Inventory/StockTransfer/StockTransfer";
import AddStockTransfer from "./Pages/Inventory/StockTransfer/AddStockTransfer";
import Adjustments from "./Pages/Inventory/StockAdjustment/Adjustments";
import AddStockAdjustment from "./Pages/Inventory/StockAdjustment/AddStockAdjustment";
import ViewStockTransfer from "./Pages/Inventory/StockTransfer/ViewStockTransfer";
import EditWarehouse from "./Pages/Inventory/UpdateRows/EditWarehouse";
import ViewSaleDetail from "./Pages/Sales/ViewSaleDetail";
import Convert from "./Pages/Conversion/Convert";
import AddConversion from "./Pages/Conversion/AddConversion";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/auth"
          element={
            <>
              <Login />
            </>
          }
        />

        <Route
          path="/"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />

              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                {/* <MasterProducts /> */}
              </div>
            </>
          }
        />

        <Route
          path="/add_product"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddProducts />
              </div>
            </>
          }
        />

        <Route
          path="/update-product/:sku"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <EditProduct />
              </div>
            </>
          }
        />

        <Route
          path="/all_products"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <MasterProducts />
              </div>
            </>
          }
        />

        <Route
          path="/warehouses"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <Warehouses />
              </div>
            </>
          }
        />

        <Route
          path="/row-rack/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <RowRackDetails />
              </div>
            </>
          }
        />

        <Route
          path="/update-row-rack/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <EditWarehouse />
              </div>
            </>
          }
        />

        <Route
          path="/add-warehouse"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddWarehouse />
              </div>
            </>
          }
        />

        <Route
          path="/stock_adjustments"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <Adjustments />
              </div>
            </>
          }
        />

        <Route
          path="/add-stock-adjustment"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddStockAdjustment />
              </div>
            </>
          }
        />

        <Route
          path="/stock_transfer"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <StockTransfer />
              </div>
            </>
          }
        />

        <Route
          path="/transfer-history-detail/:pk"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <ViewStockTransfer />
              </div>
            </>
          }
        />

        <Route
          path="/add-stock-transfer"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddStockTransfer />
              </div>
            </>
          }
        />

        <Route
          path="/all_purchases"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <Purchases />
              </div>
            </>
          }
        />

        <Route
          path="/add-purchase"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddPurchase />
              </div>
            </>
          }
        />

        <Route
          path="/update-purchase/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <EditPurchase />
              </div>
            </>
          }
        />

        <Route
          path="/barcode-purchase/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <BarcodePurchase />
              </div>
            </>
          }
        />

        <Route
          path="/allocate-purchase-goods/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <GoodAllocation />
              </div>
            </>
          }
        />

        <Route
          path="/all_sales"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <Sales />
              </div>
            </>
          }
        />

        <Route
          path="/add-sale"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddSale />
              </div>
            </>
          }
        />

        <Route
          path="/update-sale/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <EditSale />
              </div>
            </>
          }
        />

        <Route
          path="/view-sale/:id"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <ViewSaleDetail />
              </div>
            </>
          }
        />

        <Route
          path="/conversions"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <Convert />
              </div>
            </>
          }
        />

        <Route
          path="/add-conversions"
          element={
            <>
              <AuthTokenVerify />
              <Navbar />
              <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <AddConversion />
              </div>
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
