import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_all_product,
  fetch_vendor,
  create_purchase,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";
import CreateVendor from "./CreateVendor";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";

function AddPurchase() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [vendors, setVendors] = useState([]);

  const [vendor, setVendor] = useState("");
  // const [purchase_date, setP_date] = useState("");
  const [purchase_date, setP_date] = useState(getCurrentDate());
  const [reference_no, setRef_no] = useState("");

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const navigate = useNavigate();
  useEffect(() => {
    fetchVendors();
  }, []);

  const fetchVendors = () => {
    axios
      .get(fetch_vendor, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        //console.log(response.data.vendors);
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value.trim().length >= 2) {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        // console.log(response.data.data.products);
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else {
      setProducts([]);
      setDisplay(false);
    }
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 0,
      }));
    }
  };

  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const handleSubmit = async () => {
    let isValid = true;
    const invalidProducts = [];

    selectedProducts.forEach((product) => {
      if (
        !quantities[product.product_sku] ||
        quantities[product.product_sku] <= 0
      ) {
        isValid = false;
        invalidProducts.push(product.product_name);
      }
    });

    if (!isValid) {
      setOpenError(true);
      setError(
        `Please enter a valid quantity for the following products: ${invalidProducts.join(
          ", "
        )}`
      );

      return;
    }

    const purchaseData = {
      vendor_pk: parseInt(vendor),
      purchase_date: purchase_date,
      reference_number: reference_no || "",
      user_name: userDetails.name || "",
      products_detail: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]),
      })),
    };

    axios
      .post(create_purchase, purchaseData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess("Purchase created successfully!");
          setSelectedProducts([]);
          setProducts([]);
          setSearchInput("");
          setRef_no("");
          setP_date("");
          setVendor("");
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setOpenError(true);
        setError(error.response.data.message);
      });
  };

  const handleButtonClick = () => {
    window.open("/add_product", "_blank");
  };
  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <CreateVendor
        openvendor={openvendor}
        handleOpenVendor={handleOpenVendor}
        handleCloseVendor={handleCloseVendor}
        fetchVendors={fetchVendors}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <h3 className="page-title">Add Purchase</h3>

          <div className="row my-4">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-4 my-2" style={{ display: "flex" }}>
                    <FormControl fullWidth size="small" required>
                      <InputLabel id="vendor_name">Select Vendor</InputLabel>
                      <Select
                        labelId="vendor_name"
                        label="Select Vendor"
                        value={vendor}
                        onChange={(e) => setVendor(e.target.value)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {vendors.map((vendor) => (
                          <MenuItem key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: "2px" }}
                      onClick={handleOpenVendor}
                      size="small"
                    >
                      Add
                    </Button>
                  </div>
                  <div className="col-lg-2 my-2">
                    <TextField
                      id="outlined-date"
                      type="date"
                      value={purchase_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setP_date(e.target.value)}
                    />
                  </div>

                  <div className="col-lg-3 my-2">
                    <TextField
                      label="Reference No."
                      id="outlined-ref"
                      value={reference_no}
                      size="small"
                      fullWidth
                      onChange={(e) => setRef_no(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-3 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    <div>Bill to:</div>
                    <div style={{ marginLeft: "5px" }}>
                      <b>Kuber Turbo Tech</b>
                      <br></br>
                      Delhi, IN
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card my-2 "
                style={{ padding: "20px 15px 2px 15px" }}
              >
                <div className="row">
                  <div className="col-10">
                    <TextField
                      onChange={handleInputChange}
                      value={searchInput}
                      fullWidth
                      size="small"
                      id="search-products"
                      label="Search Products"
                      variant="outlined"
                    />
                    {displaySearch ? (
                      <>
                        <div
                          id="searchList"
                          ref={searchListRef}
                          style={{
                            border: "1px solid #dedede",
                            position: "absolute",
                            zIndex: "2",
                            marginTop: "3px",
                            maxHeight: "50vh",
                            overflowY: "scroll",

                            backgroundColor: "white",
                            width: "92.4%",
                          }}
                        >
                          {products.map((product, index) => (
                            <>
                              <div
                                className="card p-3"
                                style={{ display: "flex" }}
                                key={index}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isProductSelected(product)}
                                      onChange={() =>
                                        handleCheckboxChange(product)
                                      }
                                      name="check"
                                    />
                                  }
                                  label={
                                    product.product_sku +
                                    " : " +
                                    product.product_name
                                  }
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="col-2 ">
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      size="small"
                      onClick={handleButtonClick}
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3 my-2">
                <h5>Purchase Summary</h5>
                <div className="row" style={{ marginTop: "13px" }}>
                  <div className="col-6 my-2">Total Products</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    {selectedProducts.length}
                  </div>
                  <div className="container my-3" style={{ display: "flex" }}>
                    <Button
                      variant="outlined"
                      style={{ width: "90%" }}
                      onClick={handleSubmit}
                    >
                      Confirm Purchase
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      style={{ width: "10%", marginLeft: "2px" }}
                      onClick={handleSubmit}
                    >
                      Draft
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <div className="products_table_class">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>
                          <TableCell align="left">Part No.</TableCell>
                          <TableCell align="left">OEM No.</TableCell>

                          <TableCell align="right">Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  style={{ width: "6.5vw" }}
                                  scope="row"
                                >
                                  {product.product_sku}
                                </TableCell>
                                <TableCell align="left">
                                  {product.product_name}
                                </TableCell>
                                <TableCell align="left">
                                  {product.part_number}
                                </TableCell>
                                <TableCell align="left">
                                  {product.oem_number}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ maxWidth: "4vw" }}
                                >
                                  <TextField
                                    label="Qty"
                                    size="small"
                                    value={
                                      quantities[product.product_sku] || ""
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    variant="outlined"
                                  />
                                </TableCell>

                                <TableCell align="right">
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      handleDeleteProduct(product.product_sku)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPurchase;
