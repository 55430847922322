import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_warehouse, apiKey } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// import ImportProducts from "./ImportProductsModal";
import Delete from "../../Messages/DeleteWarehouse";
import CustomToolbar2 from "../../Components/CustomToolbar2";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./UpdateRows/EditWarehouse";
import DeleteWarehouse from "../../Messages/DeleteWarehouse/DeleteWarehouse";

function Warehouses() {
  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const [openError, setOpenError] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const [address, setAddress] = useState("");
  const [warepk, setWarePk] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleEditClick = (id, ware_house, address) => {
    setOpenModal(true);
    setWarePk(id);
    setWarehouse(ware_house);
    setAddress(address);
  };

  const handleViewClick = (sku) => {
    navigate(`/row-rack/${sku}`);
  };

  const [pks, setPks] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "ware_house", headerName: "Warehouse", width: 200 },
    {
      field: "address",
      headerName: "Address",
      width: 500,
      renderCell: (params) => (
        <span>{params.row.address ? params.row.address : "-"}</span>
      ),
    },
    { field: "allocated", headerName: "Allocated", width: 300 },

    {
      field: "unallocated",
      headerName: "Unallocated",
      width: 200,
      renderCell: (params) => (
        <span style={{ color: "red" }}>{params.row.unallocated}</span>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => handleViewClick(params.row.id)}
          >
            View
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() =>
              handleEditClick(
                params.row.id,
                params.row.ware_house,
                params.row.address
              )
            }
          >
            Edit
          </Button>

          {/* <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => handleDeleteWarehouse(params.row.id)}
          >
            Delete
          </Button> */}
        </>
      ),
    },
  ];

  const [openDeleteWarehouse, setOpenDeleteWarehouse] = useState(false);
  const [ware_id, setWareId] = useState(false);
  const handleDeleteWarehouse = (wareid) => {
    setWareId(wareid);
    setOpenDeleteWarehouse(true);
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Delete
        selectedPKs={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      {/* <ImportProducts
        openImport={openImport}
        setOpenImport={setOpenImport}
        fetchData={fetchData}
      /> */}

      <DeleteWarehouse
        openDeleteWarehouse={openDeleteWarehouse}
        setOpenDeleteWarehouse={setOpenDeleteWarehouse}
        ware_id={ware_id}
        fetchData={fetchData}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        warepk={warepk}
        warehouse={warehouse}
        address={address}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Inventory
            </Typography>
            <Typography sx={{ color: "text.primary" }}>Warehouses</Typography>
          </Breadcrumbs>
          {/* <h3 className="page-title">Inventory</h3> */}
          <Button
            variant="outlined"
            size="small"
            style={{ float: "right", marginTop: "-3.5vh" }}
            onClick={() => navigate("/add-warehouse")}
          >
            Add Warehouse
          </Button>
          <div
            style={{
              height: "85vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar2 }}
              disableRowSelectionOnClick
              rows={warehouses}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Warehouses;
