const prefix = "https://server.kuberturbotech.com";
// const prefix = "http://192.168.1.17:8001";

// URLS //
export const loginURL = `${prefix}/api/login/`;
export const checkTokenValidity = `${prefix}/api/check-token-validity/`;
export const getNextSKU = `${prefix}/api/get-next-sku/`;
export const getTypeBrand = `${prefix}/api/get-type-and-brand/`;

export const add_product = `${prefix}/api/add-product/`;
export const import_product = `${prefix}/api/import-products/`;
export const fetch_all_product = `${prefix}/api/get-all-products/`;
export const fetch_product_detail = `${prefix}/api/get-product-detail/`;
export const update_product = `${prefix}/api/update-product/`;
export const delete_products = `${prefix}/api/delete-products/`;

export const add_warehouse = `${prefix}/api/add-warehouse/`;
export const edit_warehouse = `${prefix}/api/edit-warehouse/`;
export const delete_warehouse = `${prefix}/api/delete-warehouse/`;
export const add_row = `${prefix}/api/add-row/`;
export const edit_row = `${prefix}/api/edit-row/`;
export const delete_row = `${prefix}/api/delete-row/`;
export const add_rack = `${prefix}/api/add-rack/`;
export const edit_rack = `${prefix}/api/edit-rack/`;
export const delete_rack = `${prefix}/api/delete-rack/`;
export const add_bin = `${prefix}/api/add-bin/`;
export const edit_bin = `${prefix}/api/edit-bin/`;
export const delete_bin = `${prefix}/api/delete-bin/`;
export const fetch_racks = `${prefix}/api/fetch-racks/`;
export const fetch_bins = `${prefix}/api/fetch-bins/`;
export const fetch_warehouse = `${prefix}/api/fetch-warehouses/`;

export const create_vendor = `${prefix}/api/create-vendor/`;
export const fetch_vendor = `${prefix}/api/fetch-vendor/`;

export const create_purchase = `${prefix}/api/create-purchase/`;
export const fetch_purchase = `${prefix}/api/fetch-purchase/`;
export const fetch_purchase_detail = `${prefix}/api/fetch-purchase-detail/`;
export const edit_purchase = `${prefix}/api/edit-purchase/`;
export const delete_purchase = `${prefix}/api/delete-purchase/`;

export const allocate_inventory = `${prefix}/api/allocate-inventory/`;
export const fetch_inventory = `${prefix}/api/fetch-inventory/`;
export const delete_single_inventory = `${prefix}/api/delete-inventory/`;
export const fetchProductsForTransfer = `${prefix}/api/fetch-product-for-transfer/`;
export const TransferInventory = `${prefix}/api/transfer-inventory/`;
export const TransferHistory = `${prefix}/api/fetch-transfer-history/`;
export const TransferHistoryDetails = `${prefix}/api/fetch-transfer-history-items/`;
export const TransferHistoryDetailsInven = `${prefix}/api/fetch-transfer-history-items-inventory/`;

export const convertProducts = `${prefix}/api/convert-products/`;

export const add_customer = `${prefix}/api/add-customer/`;
export const fetch_customer = `${prefix}/api/fetch-customers/`;
export const fetch_customer_address = `${prefix}/api/fetch-customer-address/`;
export const getLastPrice = `${prefix}/api/refresh-last-price/`;

export const create_sale = `${prefix}/api/create-sale/`;
export const fetch_sale = `${prefix}/api/fetch-sale/`;
export const fetch_sale_detail = `${prefix}/api/sale-detail/`;
export const delete_sale = `${prefix}/api/delete-sale/`;

export const fetchRowInven = `${prefix}/api/fetch-row-inventory/`;
export const fetchProductInven = `${prefix}/api/fetch-product-inventory/`;
export const inventory_Stock_In = `${prefix}/api/stock-in/`;
export const inventory_Stock_Out = `${prefix}/api/stock-out/`;
export const allocateinventory_Stock_Out = `${prefix}/api/allocate-inventory-from-product/`;
export const adjustmentHostory = `${prefix}/api/adjustment-history/`;

export const bulk_Stock_In = `${prefix}/api/bulk-stock-in/`;
export const bilk_Stock_Out = `${prefix}/api/bulk-stock-out/`;

// API KEY
export const apiKey = "Ankur.XhykdhmepsqXiWbTlMwVQ.kuberturboTech";
