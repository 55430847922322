import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import {
  apiKey,
  fetch_warehouse,
  allocateinventory_Stock_Out,
} from "../../../Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AssignRackModal({
  setOpenModal,
  openModal,
  unallocated,
  p_sku,
  warehouseId,
  fetchDataa,
}) {
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [bins, setBins] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const [success, setSuccess] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [allocations, setAllocations] = useState([
    {
      warehouse_id: warehouseId,
      row: null,
      rack: null,
      bin: null,
      qty: "",
    },
  ]);

  useEffect(() => {
    if (openModal) {
      fetchWarehouses();
    }
  }, [openModal]);

  const fetchWarehouses = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        const filteredWarehouses = allWarehouses.filter(
          (warehouse) => warehouse.id === warehouseId
        );
        if (filteredWarehouses.length) {
          const selectedWarehouse = filteredWarehouses[0];
          setWarehouses(filteredWarehouses);
          setWarehouse(selectedWarehouse.ware_house);
          setRows(selectedWarehouse.rows);
          // console.log(selectedWarehouse.rows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowChange = (index, rowId) => {
    const selectedRow = rows.find((row) => row.id === rowId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      warehouse_id: warehouseId,
      row: selectedRow || [],
      rack: null,
      bin: null,
    };
    setAllocations(newAllocations);
    setRacks(selectedRow ? selectedRow.racks : []);
    setBins([]);
  };

  const handleRackChange = (index, rackId) => {
    const selectedRack = racks.find((rack) => rack.id === rackId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      rack: selectedRack,
      bin: null,
    };
    setAllocations(newAllocations);
    setBins(selectedRack ? selectedRack.bins : []);
  };

  const handleBinChange = (index, binId) => {
    const selectedBin = bins.find((bin) => bin.id === binId);
    const newAllocations = [...allocations];
    newAllocations[index] = {
      ...newAllocations[index],
      bin: selectedBin,
    };
    setAllocations(newAllocations);
  };

  const handleQtyChange = (index, qtyValue) => {
    const newQty = parseFloat(qtyValue) || "";

    const totalQty = allocations.reduce((total, alloc, idx) => {
      return idx === index
        ? total + newQty
        : total + (parseFloat(alloc.qty) || "");
    }, 0);

    if (totalQty > unallocated) {
      setErrorr("Total quantity exceeds unallocated amount.");
      const updatedAllocations = [...allocations];
      updatedAllocations[index] = {
        ...updatedAllocations[index],
        qty: "",
      };
      return;
    }

    const updatedAllocations = [...allocations];
    updatedAllocations[index] = {
      ...updatedAllocations[index],
      qty: newQty,
    };
    setAllocations(updatedAllocations);
    setErrorr("");
  };

  const addAllocationRow = () => {
    setAllocations([
      ...allocations,
      { row: null, rack: null, bin: null, qty: "" },
    ]);
  };

  const deleteAllocationRow = (index) => {
    const newAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(newAllocations);
  };

  const handleSubmit = () => {
    // console.log(allocations);
    const isValid = allocations.every(
      (alloc) => alloc.row && alloc.rack && alloc.qty
    );

    if (!isValid) {
      setError("Please fill out all fields for each allocation.");
      return;
    }

    const allocationsToSend = allocations.map((alloc) => ({
      warehouse_id: warehouseId,
      row_id: alloc.row.id,
      rack_id: alloc.rack.id,
      bin_id: alloc.bin ? alloc.bin.id : null, // Handle case where bin might be optional
      qty: alloc.qty,
    }));

    const data_Send = {
      sku: p_sku,
      user_name: userDetails.name,
      allocations: allocationsToSend,
    };

    // console.log(data_Send, "final submit");
    axios
      .post(allocateinventory_Stock_Out, data_Send, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response);
        setSuccess("Product allocated successfully!");

        fetchDataa();
        setAllocations([{ row: null, rack: null, bin: null, qty: "" }]);
        setOpenSuccess(true);
        setOpenModal(false); // Close the modal on successful submission
      })
      .catch((error) => {
        console.log(error);
        setError("An error occurred while allocating the product.");
        setOpenError(true);
      });
  };

  const handleClose = () => {
    setOpenModal(false);
    resetStates();
    setError(""); // Clear error on close
  };

  const resetStates = () => {
    setAllocations([{ row: null, rack: null, bin: null, qty: "" }]);

    setRows([]);
    setRacks([]);
    setBins([]);
    setError("");
    setSuccess("");
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Allocate Inventory for <b style={{ color: "black" }}>{warehouse}</b>
          </Typography>

          <p style={{ color: "red" }}>Unallocated Quantity: {unallocated}</p>

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
            }}
          >
            {allocations &&
              allocations.map((allocation, index) => (
                <div key={index} className="row my-1 add_warehouse_div">
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...rows.map((row) => ({
                          label: row.row_name,
                          value: row.id,
                        })),
                      ]}
                      value={
                        allocation.row
                          ? {
                              label: allocation.row.row_name,
                              value: allocation.row.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleRowChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      } // Add this line
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Row"
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...racks.map((rack) => ({
                          label: rack.rack_name,
                          value: rack.id,
                        })),
                      ]}
                      value={
                        allocation.rack
                          ? {
                              label: allocation.rack.rack_name,
                              value: allocation.rack.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleRackChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Rack"
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-3">
                    <Autocomplete
                      disablePortal
                      size="small"
                      options={[
                        ...bins.map((bin) => ({
                          label: bin.bin_name,
                          value: bin.id,
                        })),
                      ]}
                      value={
                        allocation.bin
                          ? {
                              label: allocation.bin.bin_name,
                              value: allocation.bin.id,
                            }
                          : null
                      }
                      onChange={(e, newValue) => {
                        if (newValue) {
                          handleBinChange(index, newValue.value);
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{ width: "100%" }}
                          {...params}
                          label="Bin"
                        />
                      )}
                    />
                  </div>

                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      type="number"
                      label="Quantity"
                      value={allocation.qty}
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                      fullWidth
                      helperText={
                        errorr &&
                        (index === allocations.length - 1 ? errorr : "")
                      }
                    />
                  </div>

                  <div className="col-lg-1 ">
                    <IoIosAddCircle
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                      onClick={addAllocationRow}
                    />
                    {allocations.length > 1 && (
                      <FaTrashAlt
                        onClick={() => deleteAllocationRow(index)}
                        style={{
                          fontSize: "18px",
                          marginLeft: "10px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AssignRackModal;
