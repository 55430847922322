import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  apiKey,
  fetch_warehouse,
  convertProducts,
  fetch_all_product,
  fetchProductInven,
  fetchProductsForTransfer,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { MdInventory } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { debounce } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import CustomToolbar from "../../Components/CustomToolbar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Autocomplete from "@mui/material/Autocomplete";
import AssignModal from "./Conversion_ProductModal";

function AddConversion() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [productInventories, setProductInventories] = useState({});
  const [unallocated, setUnallocated] = useState({});
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);
  const [warehouses, setWarehouses] = useState([]);
  const [targetProducts, setTargetProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchWarehouse();
    fetchInven();
    fetchTargetProduct();
  }, []);

  const fetchInven = (sku) => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const { row_inventory, obj } = response.data;

        // Update inventory for the specific SKU
        setProductInventories((prevInventories) => ({
          ...prevInventories,
          [sku]: row_inventory,
        }));

        // Update unallocated quantities for the specific SKU
        setUnallocated((prev) => ({
          ...prev,
          [sku]: obj.unallocated,
        }));
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses); // Set all warehouses
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchTargetProduct = () => {
    axios
      .post(
        fetch_all_product,
        { search: searchQuery },
        {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        const fetchedProducts = response.data.data.products;
        // console.log(fetchedProducts, "dfsefsedf");
        setTargetProducts(fetchedProducts);
        // setDisplay(true);
      });
  };

  const fetchProducts = useCallback(() => {
    try {
      axios
        .get(fetchProductsForTransfer, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            search: searchQuery,
            warehouse_id: parseInt(warehouse),
          },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setDisplay(true);
        })
        .catch((error) => {
          console.error(error);
        });

      // console.log(response.data.data.products);
    } catch (error) {
      setProducts([]);
      setDisplay(false);
    }
  }, [searchQuery, warehouse]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    []
  );

  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = (id) => {
    // Filter out the row with the matching id
    const updatedRows = selectedProducts.filter((row) => row.id !== id);
    setSelectedProducts(updatedRows);
  };

  const stockout = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 500,
    },

    {
      field: "convert",
      headerName: "Convert to",
      width: 600,
      renderCell: (params) => (
        <>
          <Autocomplete
            style={{ marginTop: "5px" }}
            size="small"
            options={targetProducts.map(
              (option) => option.product_sku + " > " + option.product_name
            )}
            getOptionDisabled={(option) =>
              option.split(" > ")[0] === params.row.product_sku
            }
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              handleProductSelect(
                params.row.product_sku,
                newValue.split(" > ")[0]
              );
            }}
          />
          <Autocomplete
            size="small"
            disableClearable
            options={targetProducts.map(
              (option) => option.product_sku + " > " + option.product_name
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search input"
                slotProps={{
                  input: {
                    ...params.InputProps,
                    type: "search",
                  },
                }}
              />
            )}
            getOptionDisabled={(option) =>
              option.split(" > ")[0] === params.row.product_sku
            }
            onChange={(event, newValue) => {
              handleProductSelect(
                params.row.product_sku,
                newValue.split(" > ")[0]
              );
            }}
          />
        </>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            onClick={() => handleInven(params.row.product_sku)}
            size="small"
          >
            Inventory
          </Button>

          <Button
            variant="outlined"
            color="error"
            style={{ marginLeft: "5px" }}
            onClick={() => handleDelete(params.row.id)}
            size="small"
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const [p_sku, setSKU] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedToProducts, setSelectedToProducts] = useState({});
  const [inven, setInven] = useState([]);

  const handleInven = (sku) => {
    setOpenModal(true);
    setSKU(sku);

    try {
      axios
        .get(fetchProductInven, {
          params: {
            warehouse_id: parseInt(warehouse),
            sku: sku,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setInven(response.data.row_inventory);
          setUnallocated(response.data.obj.unallocated);
          setOpenModal(true);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [refreshKey, setRefreshKey] = useState(0);

  const handleProductSelect = (productSku, toProductSku) => {
    setSelectedToProducts((prevState) => ({
      ...prevState,
      [productSku]: toProductSku,
    }));
  };

  let savedQuantities = JSON.parse(localStorage.getItem("conversions"));

  const handleConfirm = () => {
    let formattedData = {
      warehouse_id: parseInt(warehouse),
      user_name: userDetails.name,
      note: notes,
      products: selectedProducts.map((product) => {
        const toProductSku =
          selectedToProducts[product.product_sku] || product.product_sku;

        // Extract inventory details from savedQuantities based on from_product_sku
        const productInvenDetails = savedQuantities[product.product_sku] || [];

        return {
          from_product_sku: product.product_sku,
          to_product_sku: toProductSku,
          // total_qty: calculateTotalQuantity(product.product_sku),
          inventory_detail: productInvenDetails,
        };
      }),
    };

    try {
      axios
        .post(convertProducts, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Conversion Created Successfully!`);

            localStorage.removeItem("conversions");
            setProducts([]);
            setDisplay(false);
            setSelectedProducts([]);
            setRefreshKey((prevKey) => prevKey + 1);
            setNotes("");
            // navigate("/stock_transfer");
          } else {
            setError(response.data.message);
            setOpenError(true);
            setDisplay(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    // Clear specific items from local storage on component mount (page load or refresh)
    localStorage.removeItem("conversions");
  }, []);

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AssignModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        warehouseId={warehouse}
        p_sku={p_sku}
        setInven={setInven}
        inven={inven}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-2)}
            >
              Inventory
            </Typography>
            <Typography
              color="inherit"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              Conversions
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Add Conversion
            </Typography>
          </Breadcrumbs>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-3 p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    value={warehouse} // Ensure this is controlled by state
                    onChange={(e) => {
                      const selectedWarehouse = warehouses.find(
                        (wh) => wh.id === e.target.value
                      );
                      if (selectedWarehouse) {
                        setWarehouse(selectedWarehouse.id);
                        setSelectedProducts([]);

                        setDisplay(false);
                      }
                    }}
                    label="Warehouse"
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-4 p-3">
                <TextField
                  size="small"
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={1}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>

              <div className="col-lg-3 p-3">
                <TextField
                  key={refreshKey}
                  fullWidth
                  type="search"
                  size="small"
                  label="Search Products..."
                  variant="outlined"
                  onInput={handleSearchChange}
                />
                {displaySearch ? (
                  <>
                    <div
                      id="searchList"
                      ref={searchListRef}
                      style={{
                        border: "1px solid #dedede",
                        position: "absolute",
                        zIndex: "2",
                        right: "3.5vw",
                        marginTop: "3px",
                        maxHeight: "50vh",
                        overflowY: "scroll",

                        backgroundColor: "white",
                        width: "40%",
                      }}
                    >
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProductSelected(product)}
                                  onChange={() => handleCheckboxChange(product)}
                                  name="check"
                                />
                              }
                              label={
                                product.product_sku +
                                " : " +
                                product.product_name
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="col-lg-2 p-3">
                <Button
                  disabled={!warehouse}
                  style={{ float: "right" }}
                  onClick={handleConfirm}
                  variant="contained"
                >
                  Confirm Conversion
                </Button>
              </div>
            </div>

            <div
              className="p-3"
              style={{
                height: "77vh",
                marginTop: "-15px",
                width: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              <DataGrid
                disableRowSelectionOnClick
                slots={{ toolbar: CustomToolbar }}
                rows={selectedProducts}
                columns={stockout}
                pageSize={10}
                // checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddConversion;
