import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AssignWare from "./RowRackInvenAdjust";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1200px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RowRackInvenPopUp({
  fetchData,
  setOpenModal,
  openModal,
  inven,
  row_name,
  warehouse,
}) {
  const [error, setError] = useState("");
  const [inven_id, setID] = useState("");
  const [product, setProduct] = useState("");
  const [quantity, setQty] = useState("");
  const [openModal2, setOpenModal2] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    setError(""); // Clear error on close
  };

  const handleEditClick = (id, product, qty) => {
    setOpenModal2(true);
    setID(id);
    setProduct(product);
    setQty(qty);
  };

  return (
    <>
      <AssignWare
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        inven={inven_id}
        product={product}
        qty={quantity}
        fetchData={fetchData}
      />

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Inventory
            </Typography>
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Row Rack
            </Typography>
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              {warehouse}
            </Typography>
            <Typography sx={{ color: "text.primary" }}>{row_name}</Typography>
          </Breadcrumbs>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">SKU</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">Rack</TableCell>
                  <TableCell align="left">Bin</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inven.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row.product.product_sku}
                    </TableCell>
                    <TableCell align="left">
                      {row.product.product_name}
                    </TableCell>
                    <TableCell align="left">
                      {row.rack ? row.rack.rack_name : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.bin_ref ? row.bin_ref.bin_name : "-"}
                    </TableCell>
                    <TableCell align="left">{row.quantity}</TableCell>
                    <TableCell align="left">
                      <Button
                        size="small"
                        onClick={() =>
                          handleEditClick(row.id, row.product, row.quantity)
                        }
                        variant="outlined"
                      >
                        Adjust
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            style={{ marginTop: "8px" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default RowRackInvenPopUp;
